<template>
<div class="alert alert-success" role="alert" >
	新增合同
</div>	

<!--Card -->
<div class="row was-validated">
	<div class="col-1"></div>
	<div class="col">
		<!-- 表格 -->
		<table class="table table-bordered">
		  <tbody>
		    <tr>
		      <th scope="row">合同编码</th>
		      <td>
				  <input type="text" class="form-control " readonly id="htId" name="htId"  aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" placeholder="系统自动生成">
			  </td>
		      <th scope="row">合同类型</th>
		      <td>
				  <select class="form-control" id="htType" v-model="htOil.htType" required>
				  	<option>销售合同</option>
				  	<option>采购合同</option>
				  </select>
			  </td>
		    </tr>
		    <tr>
		      <th scope="row">客商名称</th>
		      <td>
				<div class="input-group">
				  <input type="text" readonly class="form-control" id="sCustomerName"  :key="htOil.customerName" v-model="htOil.customerName" :placeholder="htOil.customerName">
				  <button type="button" class="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" @click="search()">选择</button>
				</div>  
				<!--模态框-->
				<div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
				  <div class="modal-dialog modal-dialog-scrollable">
					<div class="modal-content">
					  <div class="modal-header">
						<h5 class="modal-title" id="staticBackdropLabel">选择客商</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					  </div>
					  <div class="modal-body">
						<div class="input-group sticky-top">
						  <div class="custom-file">
							<input type="text"  class="form-control" id="searchText" v-model="searchText" placeholder="输入客商名称" @keyup.enter="search()">
						  </div>
						  <div class="input-group-append">
							 <button type="button" class="btn btn-outline-secondary" @click="search()">搜索</button>
						  </div>
						</div>
						<br>
						<!--model表格 -->
						<table class="table table-bordered">
						  <thead>
							<tr>
							  <th scope="col">客商编码</th>
							  <th scope="col">客商名称</th>
							  <th scope="col">操作</th>
							</tr>
						  </thead>
						  <tbody>
							  <tr v-for="customer in customerList" :key="customer">
								<th scope="row">{{customer.customerId}}</th>
								<td>{{customer.customerName}}</td>
								<td>
									<button type="button" class="btn btn-primary" @click="select(customer.customerName,customer.customerId)" data-bs-dismiss="modal">选中</button>
								</td>
							  </tr>
						  </tbody>
						</table>
						<!--stop model表格 -->
					  </div>
					  <div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">关闭</button>
					  </div>
					</div>
				  </div>
				</div>



				<!--stop模态框 -->
				
				<!-- <input type="text" readonly class="form-control" id="customerName" name="customerName" v-model="htOil.customerName" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" required> -->
			  </td>
		      <th scope="row">合同生效日期</th>
		      <td>
				  <input type="date" class="form-control" name="activationDate" v-model="htOil.activationDate" required>
			  </td>
		    </tr>
		    <tr>
		      <th scope="row">合同终止日期</th>
		      <td>
				  <input type="date" class="form-control" id="stopDate" name="stopDate" v-model="htOil.stopDate" required>
			  </td>
		      <th scope="row">物料名称</th>
		      <td>
				  <select class="form-control" id="materialName" v-model="htOil.materialName" required>
				  	<option>《------请选择类型------》</option>
					<option>0号车用柴油（VI）</option>
				  	<option>92#汽油</option>
				  	<option>95#汽油</option>
				  	<option>98#汽油</option>
				  	<option>轻质燃料油</option>
				  	<option>燃料油</option>
				  	<option>F-PD1炉用燃料油</option>
				  	<option>轻质船用燃料油</option>
				  	<option>船用馏分燃料油DMA</option>
				  </select>
			  </td>
		    </tr>			
		    <tr>
		      <th scope="row">油品规格</th>
		      <td>
				<input type="text" class="form-control" id="oilSpecification" name="oilSpecification" v-model="htOil.oilSpecification" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" required>
			  </td>
		      <th scope="row">发货油库</th>
		      <td>
				  <select class="form-control" id="outputOd" v-model="htOil.outputOd" required>
				  	<option>本库</option>
				  	<option>外库</option>
				  </select>
			  </td>
		    </tr>	
		    <tr>
		      <th scope="row">提货油库</th>
		      <td>
				  <input type="text" required class="form-control" id="getOd" name="getOd" v-model="htOil.getOd" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
		      <th scope="row">结算单价</th>
		      <td>
				  <input type="number" required class="form-control" step = "0.01" placeholder="保留两位小数" id="oilPrice" name="oilPrice" v-model="htOil.oilPrice" >
			  </td>
		    </tr>	
		    <tr>
		      <th scope="row">合同数量</th>
		      <td>
				  <input type="number" required class="form-control" step = "0.001" placeholder="保留三位小数" id="htNum" name="htNum" v-model="htOil.htNum" >
			  </td>
		      <th scope="row">业务员</th>
		      <td>
				  <input type="text" required class="form-control" id="salesman" name="salesman" v-model="htOil.salesman" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
		    </tr>
		    <tr>
		      <th scope="row">油品收付款协议</th>
		      <td>
				  <select class="form-control" required id="agreement1" name="agreement1" v-model="htOil.agreement">
				    <option>先货后款</option>
				  	<option>先款后货(全款支付)</option>
				  	<option>先款后货(款到拆单)</option>
				  	<option>先款后货(款到放行)</option>
				  </select>
			  </td>
		      <th scope="row">保证金比例</th>
		      <td>
				  <input type="text" required class="form-control" id="bondRate" name="bondRate" v-model="htOil.bondRate" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
		    </tr>													
		    <tr>
		      <th scope="row">运输方式名称</th>
		      <td>
				  <select class="form-control" required id="transportName" name="transportName" v-model="htOil.transportName">
				  	<option>公司车运输</option>
				  	<option>客户车自提</option>
				  	<option>公司船运输</option>
				  	<option>客户船自提</option>
				  </select>
			  </td>
		      <th scope="row"></th>
			  <td>
			  </td>
		    </tr>																
		    <tr>
		      <th scope="row">合同备注</th>
		      <td colspan="3">
				  <textarea class="form-control" required id="remarks" name="remarks" v-model="htOil.remarks" rows="5"></textarea>
			  </td>
		    </tr>
		  </tbody>
		</table>
		
		<!--stop表格-->
		
		
		
		
			<form>																																										
		  <div class="form-group row">
		    <div class="col-sm-3 text-center">
		    </div>			  
		    <div class="col-sm-3 text-center">
			  <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal">确认新增</button>
		    </div>
		    <div class="col-sm-3 text-center">
		      <button type="button" class="btn btn-secondary" @click="goBack()">放弃修改</button>
		    </div>
		    <div class="col-sm-3 text-center">
		    </div>			
		  </div>
		  
		  
		<!-- Modal -->
			<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
			  <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
			    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
			  </symbol>
			  <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
			    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
			  </symbol>
			  <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
			    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
			  </symbol>
			</svg>
			<div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
			  <div class="modal-dialog modal-dialog-centered">
			    <div class="modal-content">
			      <div class="modal-header alert-warning">
					<svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"/></svg>
			        <h5 class="modal-title" id="staticBackdropLabel">检查</h5>
			        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			      </div>
			      <div class="modal-body">
			        已核对信息，确认新增合同？
			      </div>
			      <div class="modal-footer">
					<button type="button" class="btn btn-warning col-sm-3" data-bs-dismiss="modal" @click="addHtOil()">是</button>
			        <button type="button" class="btn btn-secondary col-sm-3" data-bs-dismiss="modal">否</button>
			      </div>
			    </div>
			  </div>
			</div>
			<!--stop模态框 -->		  
		  
			</form>
			
	</div>
	<div class="col-1"></div>
</div>
<!--Stop Card -->



</template>

<script>
	import axios from 'axios';
	import router from '../../router/index.js';
	import store from '../../store/index.js';
	import {ref,reactive} from 'vue';


	
				
  export default {
	  name:'HtOilAdd',
	  setup(){
	let customer=ref('');
	let searchText=ref('');
	let customerList=ref('');
	// let customerName=ref('');
	let htOil=reactive({
		
	});
	
	axios.post('/customer/getCustomerListLike',searchText).then(function (response) {
		console.log(response);
		customerList.value=response.data;
		})
		.catch(function (error) {
		console.log(error);
		});		
//搜索客商			
			function search(){					
				axios.post('/customer/getCustomerListLike',searchText).then(function (response) {
					console.log(response);
					customerList.value=response.data;
					})
					.catch(function (error) {
					console.log(error);
					});	
			}	
//选择客商
			function select(cn,ci){	
				htOil.customerName=cn;
				htOil.customerId=ci;
			}
//新增合同
			function addHtOil(){		
				axios.post('/ht/addHtOil',htOil).then(function (response) {
					console.log(response);
					alert("添加成功");
					store.commit('setHtOil',response.data);
					store.commit('setSuccessStatus');
					router.push({ name: 'HtOilDetail' });
					})
					.catch(function (error) {
					store.commit('setFailStatus');
					console.log(error);
					});	
			}
//返回上一层
			function goBack(){
				router.push({ name: 'HtOil'});
			}
					
		  return{
			  htOil,
			  addHtOil,
			  goBack,
			  search,
			  searchText,
			  customerList,
			  customer,
			  select
		  }
	  }
  }
</script>

<style>
	
</style>

