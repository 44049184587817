<template>
	<div class="row">
		<div class="col-1">
		</div>
		<div class="col-10">
			<router-link to="/"><img alt="" src="../assets/manageLogo.png" style="max-width:280px;
			max-height:80px;
			padding-left:0px;
			padding-top:11px;"></router-link>
			<font size=5 >&#12288;&#12288;数据管理系统&#12288;</font>
			<font size=4 data-bs-toggle="modal" data-bs-target="#staticBackdrop">&#12288;&#12288; V0.7.0（更新日志）</font>
			<!-- Modal -->								
			<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
			  <div class="modal-dialog  modal-dialog-scrollable">
				<div class="modal-content">
				  <div class="modal-header">
					<h5 class="modal-title" id="staticBackdropLabel">更新日志</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				  </div>
				  <div class="modal-body">
					<h4>V0.7.0</h4>
					<h6 class="text-right">2022.11.20-2022.11.27</h6>
					<font size=4>新增Excel导出功能，修复已知问题。</font>
					<hr>
					<h4>V0.6.0</h4>
					<h6 class="text-right">2022.10.22-2022.10.27</h6>
					<font size=4>新增用户登录、操作日志功能，修复账务管理缓存问题。</font>
					<hr>
					<h4>V0.5.2</h4>
					<h6 class="text-right">2022.10.19-2022.10.21</h6>
					<font size=4>补充管理账务的修改和删除功能，改动账单查询内容，修复数据刷新问题。</font>
					<hr>
					<h4>V0.5.1</h4>
					<h6 class="text-right">2022.10.13-2022.10.18</h6>
					<font size=4>修改流水号发号逻辑、扩容。美化界面交互。加入自动部署功能。修复错误账单逻辑，表单内容改动。</font>
					<hr>
					<h4>V0.5.0</h4>
					<h6 class="text-right">2022.10.8-2022.10.12</h6>
					<font size=4>更改界面显示，修改出入库、账务功能逻辑。</font>
					<hr>					  
					<h4>V0.4.1</h4>
					<h6 class="text-right">2022.10.2-2022.10.7</h6>
					<font size=4>修改管理账务，修复模态框问题。</font>
					<hr>
					<h4>V0.4.0</h4>
					<h6 class="text-right">2022.9.21-2022.10.1</h6>
					<font size=4>升级页面架构版本，处理不兼容部分。增强页面颜色辨识度，美化界面布局，封装引入新控件。新增管理客商账务功能。添加页面历史和刷新控制。修复模糊搜索空值问题、部分页面抖动问题。</font>
					<hr>	
					<h4>V0.3.4.Alpha</h4>
					<h6 class="text-right">2022.9.19-2022.9.20</h6>
					<font size=4>补充校验逻辑，优化界面，修复分页问题。</font>
					<hr>							
					<h4>V0.3.3.Alpha</h4>
					<h6 class="text-right">2022.9.18-2022.9.19</h6>
					<font size=4>修复已知问题。</font>
					<hr>							
					<h4>V0.3.2.Alpha</h4>
					<h6 class="text-right">2022.9.17-2022.9.18</h6>
					<font size=4>添加数字校验逻辑，修复已知问题。</font>
					<hr>
					<h4>V0.3.1.Demo</h4>
					<h6 class="text-right">2022.9.13-2022.9.16</h6>
					<font size=4>演示版本，修复合同、计划、出入库功能逻辑并添加详情页面、发号器自动更新。优化界面显示效果。</font>
					<hr>
					<h4>V0.3.0.Alpha</h4>
					<h6 class="text-right">2022.9.7-2022.9.12</h6>
					<font size=4>添加自动发号功能，视觉效果更新：优化详情页，加入校验视觉效果，修复分页和已知问题。</font>
					<hr>							
					<h4>V0.2.0.Alpha</h4>
					<h6 class="text-right">2022.9.2-2022.9.6</h6>
					<font size=4>添加分页功能，优化合同界面布局与表格展示，优化展示逻辑实现无感刷新。</font>
					<hr>
					<h4>V0.1.0.Beta</h4>
					<h6 class="text-right">2022.8.25-2022.9.1</h6>
					<font size=4>添加管理合同、计划、库存、客商功能。</font>
					<!-- <hr> -->				
				  </div>
				  <div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">关闭</button>
				  </div>
				</div>
			  </div>
			</div>								
		</div>
		<div class="col-1">
		</div>		
	</div>	
	<hr class="my-2">
	<br>
	


</template>

<script>
	export default{
		name:'Head',
		components:{
		},
		setup(){
					
				  return{
				  }
		}
	}
</script>

<style>
</style>
