<template>

<!--搜索框 -->  
<div class="alert alert-secondary " role="alert" >

  <h4 class="alert-heading">①管理提油合同</h4>
  <hr>  

  <div class="row ">
		<div class="col-3 justify-content-center">
			<div class="input-group">
			  <input type="text" class="form-control" id="searchText" v-model="searchLike.searchText" @keyup.enter="search()">
			  <button type="button" class="btn btn-outline-secondary" @click="search()">{{searchLike.searchType}}</button>
			  <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
			    <span class="visually-hidden">Toggle Dropdown</span>
			  </button>
			</div>
			<div class="collapse" id="collapseExample">
			  <div class="card card-body">
				  <a class="dropdown-item" @click="changeSearchType('检索客商信息')" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">检索客商信息</a>
				    <div role="separator" class="dropdown-divider"></div>
				    <a class="dropdown-item" @click="changeSearchType('检索合同编码')" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">检索合同编码</a>
				    <div role="separator" class="dropdown-divider"></div>
				    <a class="dropdown-item" @click="changeSearchType('检索业务员')" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">检索业务员</a>
			  </div>
			</div>
		</div>
		<div class="col">
			<!-- 分页-->
			<div class="hstack gap-2 justify-content-center">
			    <div class="">
					<nav aria-label="Page navigation example">
					  <ul class="pagination ">
						<li class="page-item"><a class="page-link">每页显示：</a></li>
					    <li class="page-item">
							<select class="form-control" id="exampleFormControlSelect1" v-model="pageInfo.pageSize" @click="getHtOilPage()">
							  <option>3</option>
							  <option>5</option>
							  <option>10</option>
							  <option>50</option>
							  <option>1000</option>
							</select>
						</li>
					  </ul>	  
					</nav>
				</div>
			    <div class="">
					<nav aria-label="Page navigation example">
					  <ul class="pagination justify-content-center">
						<li class="page-item"><a class="page-link" href="#skip" @click="showFirstPage()">1</a></li>
						<li class="page-item">
						  <a class="page-link" href="#skip" aria-label="Previous" @click="showLastPage()">
							<span aria-hidden="true">&laquo;</span>
						  </a>
						</li>
						<li class="page-item active"><a class="page-link" >{{htOilListPage.pageNum}}</a></li>
						<li class="page-item">
						  <a class="page-link" href="#skip" aria-label="Next" @click="showNextPage()">
							<span aria-hidden="true">&raquo;</span>
						  </a>
						</li>
						<li class="page-item"><a class="page-link" href="#skip" @click="showEndPage()">{{htOilListPage.pages}}</a></li>
					  </ul>
					</nav>
				</div>
				<div class="">
					<nav aria-label="Page navigation example">
					  <ul class="pagination ">
					    <li class="page-item"><input type="number" class="form-control " v-model="pageInfo.pageNum" @keyup.enter="getHtOilPage()"></li>
					    <li class="page-item"><a class="page-link" href="#skip" @click="getHtOilPage()">Go!</a></li>
					  </ul>
					</nav>
				</div>
			</div>
			<!-- stop分页-->		
		</div>
		<div class="col-2">
			<button type="button" class="btn btn-success m-1" @click="toAddHtOil()">新增合同</button>
			<button class="btn btn-success m-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample12" aria-expanded="false" aria-controls="collapseExample12">
				Excel处理
			</button>
			<div class="collapse" id="collapseExample12">
			  <div class="card card-body">
				  <div class="mb-3">
				    <input class="form-control form-control-sm" id="formFileSm" type="file">
					<div class="">
						<label class="form-label">Excle导入</label>
						<button class="btn btn-warning m-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample12" aria-expanded="false" aria-controls="collapseExample12">
							导入
						</button>						
					</div>
				  </div>
				  <div role="separator" class="dropdown-divider"></div>
				  <div class="mb-3">
				    <label class="form-label">Excle导出</label>
					<a  class="btn btn-success m-1" href="http://localhost:8080/ht/downloadFile?fileName=HtoilList.xlsx">导出</a>
				 <!-- data-bs-toggle="collapse" data-bs-target="#collapseExample12" -->
				  </div>				  
			  </div>
			</div>
		</div>
  </div>
  
</div>
<!--stop搜索框 -->   


<!--表格 -->	
<table class="table table-hover table-striped">
  
  <thead class="sticky-top table-light">	
    <tr>  <!--显示列表栏-->	
		<th scope="col">序号</th>		
		<th scope="col">合同编码</th>
		<th scope="col">合同类型</th>
		<th scope="col">客商名称</th>
		<th scope="col">发起时间</th>
		<th scope="col">终止时间</th>
		<th scope="col">物料名称</th>
	    <th scope="col">油品规格</th>
	    <th scope="col">发货油库</th>
	    <th scope="col">提货油库</th>
	    <th scope="col">结算单价</th>
	    <th scope="col">合同数量</th>
	    <th scope="col">已安排计划量</th>
	    <th scope="col">已发货量</th>
	    <th scope="col">已安排未发货量</th>
	    <th scope="col">姓名</th>
	    <th scope="col">油品收款协议</th>
	    <th scope="col">保证金比例</th>
	    <th scope="col">运输方式名称</th>
	    <th scope="col">剩余可安排量</th>
	    <th scope="col">剩余未出库量</th>
	    <th scope="col">合同备注</th>
		<th scope="col">操作日志</th>
	</tr>	
  </thead>
  <tbody>  <!--显示子列表栏-->  
	<tr v-for="(htOil,index) in htOilList" :key="htOil" >
      <th scope="row">
		  {{index+1}}
	  </th>
      <td>
		<a class="text-primary" @click="updateHtOil(htOil)">{{htOil.htId}}</a>
	  </td>	  
	  <td>
		  {{htOil.htType}}
	  </td>	      
	  <td>
		  {{htOil.customerName}}
	  </td>	 
      <td>
		  {{htOil.activationDate}}
	  </td>	  
      <td>
		  {{htOil.stopDate}}
	  </td>
      <td>
		  {{htOil.materialName}}
	  </td>	
      <td>
		  {{htOil.oilSpecification}}
	  </td>	
      <td>
		  {{htOil.outputOd}}
	  </td>	
      <td>
		  {{htOil.getOd}}
	  </td>	
      <td>
		  {{htOil.oilPrice}}
	  </td>	
      <td>
		  {{htOil.htNum}}
	  </td>	
      <td>
		  {{htOil.arrangePlan}}
	  </td>
      <td>
		  {{htOil.actualOutput}}
	  </td>																  
      <td>
		  {{htOil.arrangeWaitDelive}}
	  </td>		
      <td>
		  {{htOil.salesman}}
	  </td>																		
      <td>
		  {{htOil.agreement}}
	  </td>																		
      <td>
		  {{htOil.bondRate}}
	  </td>	
      <td>
		  {{htOil.transportName}}
	  </td>	
      <td>
		  {{htOil.remainPlan}}
	  </td>	
      <td>
		  {{htOil.remainOut}}
	  </td>	
      <td>
		  {{htOil.remarks}}
	  </td>	
	  <td>
		  {{htOil.userName}}
	  </td>																								
    </tr>
  </tbody>  
</table>
<!--stop表格 -->	
<div class="hstack gap-2 justify-content-center">
	  <nav aria-label="Page navigation example">
	  		<ul class="pagination justify-content-center">
	  		<li class="page-item"><a class="page-link" href="#skip" @click="showFirstPage()">1</a></li>
	  		<li class="page-item">
	  		  <a class="page-link" href="#skip" aria-label="Previous" @click="showLastPage()">
	  			<span aria-hidden="true">&laquo;</span>
	  		  </a>
	  		</li>
	  		<li class="page-item active"><a class="page-link" >{{htOilListPage.pageNum}}</a></li>
	  		<li class="page-item">
	  		  <a class="page-link" href="#skip" aria-label="Next" @click="showNextPage()">
	  			<span aria-hidden="true">&raquo;</span>
	  		  </a>
	  		</li>
	  		<li class="page-item"><a class="page-link" href="#skip" @click="showEndPage()">{{htOilListPage.pages}}</a></li>
	  		</ul>
	  </nav>
	  <nav aria-label="Page navigation example">
	  		<ul class="pagination">
	  		  <li class="page-item"><input type="number" class="form-control" v-model="pageInfo.pageNum"></li>
	  		  <li class="page-item"><a class="page-link" href="#skip" @click="getHtOilPage()">Go!</a></li>
	  		</ul>
	  </nav>
</div>



<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>

</template>

<script>
	import axios from 'axios';
	import router from '../../router/index.js'
	import {ref,reactive} from 'vue';
	import store from '../../store/index.js';
	
	
	
	export default{
		name:'HtOil',
		components:{
		},
		
		setup(){  
			let htOilList=ref('');
			let customerList;
			let htOil=ref('');
			let htOilListPage=ref('');
			let searchLike=reactive({
				searchText:'',
				searchType:'检索客商信息'
			})
			
			axios.post('/ht/getHtOilList',htOil)
			  .then(function (response) {
				console.log(response);
				htOilListPage.value=response.data;
				htOilList.value=htOilListPage.value.htOilList;
			  })
			  .catch(function (error) {
				console.log(error);
			  });		
//分页	
			let pageInfo=reactive({
				pageNum:1,
				pageSize:5,
				total:0,
				pages:0
			});
			htOil=store.state.htOil;

//搜索合同			
		function search(){	
			axios.post('/ht/getHtOilListLike',searchLike).then(function (response) {
				console.log(response);
				htOilList.value=response.data;
				})
				.catch(function (error) {
				console.log(error);
				});	
		}

//修改合同
		const updateHtOil= (htOil) =>{
				store.commit('setHtOil',htOil);
				console.log(htOil);
				router.push({ name: 'HtOilDetail' });
		};
			
//.....分页...........			
		const showFirstPage= () =>{
			pageInfo.pageNum=1;
			getHtOilPage();		  
		};	  
		const showLastPage= () =>{
			pageInfo.pageNum=htOilListPage.value.pageNum-1;
			getHtOilPage();
		};
		const showEndPage= () =>{
			pageInfo.pageNum=htOilListPage.value.pages;
			getHtOilPage();
		};		  
		const showNextPage= () =>{
			pageInfo.pageNum=htOilListPage.value.pageNum+1;
			getHtOilPage();
		};
		function getHtOilPage(){
			axios.post('/ht/getHtOilList',pageInfo)
			  .then(function (response) {
				console.log(response);
				htOilListPage.value=response.data;
				htOilList.value=htOilListPage.value.htOilList;
			  })
			  .catch(function (error) {
				console.log(error);
			  });	
		}
		const changeSearchType= (st) =>{
			  searchLike.searchType=st;
		  };
		const downloadExcel= () =>{
			  axios.post('/ht/downloadFile?fileName=HtoilList.xlsx')
			    .then(function (response) {
			  	console.log(response);
			    })
			    .catch(function (error) {
			  	console.log(error);
			    });	
		  };		  
//.....stop分页..................	
		const toAddHtOil= () =>{
			router.push({ name: 'HtOilAdd' });
		  };
			return{
				htOilList,
				htOil,
				search,
				customerList,
				updateHtOil,
				changeSearchType,
				toAddHtOil,
//分页			
				pageInfo,
				getHtOilPage,
				showNextPage,
				showEndPage,
				showLastPage,
				showFirstPage,
				htOilListPage,
				searchLike,
				downloadExcel
			}
		}

	}
</script>

<style>
</style>
