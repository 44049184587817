<template>
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><router-link to="/admin/PlanOil">管理提油计划</router-link></li>
    <li class="breadcrumb-item active" aria-current="page">提油计划详情</li>
  </ol>
</nav>
<div class="alert alert-primary" role="alert" >
	计划详情
</div>	



<!--Card -->
<div class="row">
	<div class="col-1"></div>
	<div class="col">
		<!-- 表格 -->
		<table class="table table-bordered">
		  <tbody>
		    <tr>
		      <th scope="row">提油号/计划号</th>
		      <td>
				  <input type="text" readonly class="form-control" id="planId" name="planId" v-model="planOil.planId" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
		      <th scope="row">合同编号</th>
		      <td>
				  <input type="text" readonly class="form-control" id="htId" name="htId" v-model="planOil.htId"  aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
		    </tr>
		    <tr>
		      <th scope="row">计划日期</th>
		      <td>
				  <input type="date"  class="form-control" id="planDate" name="planDate" v-model="planOil.planDate" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
		      <th scope="row">计划收发数量</th>
		      <td>
				  <input type="number"  class="form-control" name="planNum" v-model="planOil.planNum"  required>
			  </td>
		    </tr>
			<tr>
			  <th scope="row">类型</th>
			  <td>
				  <select class="form-control" disabled id="type" name="type" v-model="planOil.type">
				    <option>发货</option>
				  	<option>收货</option>
				  </select>
			  </td>
		      <th scope="row">已建出入库记录数：**条</th>
			  <td>
				<button type="button" class="btn btn-primary" @click="toActualOilAdd(planOil)">新建出入库记录</button>
			  </td>
			</tr>
			
			<tr class="table-secondary">
			  <th scope="row">已收发量</th>
			  <td>
				  <input type="text" readonly class="form-control" id="getNum" name="getNum" v-model="planOil.getNum" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
			  <th scope="row">剩余未收发量</th>
			  <td>
				  <input type="text" readonly class="form-control" id="remaindeNum" name="remaindeNum" v-model="planOil.remaindeNum" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
			</tr>	
			<tr class="table-secondary">
			  <th scope="row">结算单价</th>
			  <td>
				  <input type="text" readonly class="form-control" id="oilPrice" name="oilPrice" v-model="planOil.oilPrice" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
			  <th scope="row"></th>
			  <td>
			  </td>
			</tr>		
		  </tbody>
		  
		  
		</table>
		<!--stop表格-->
		<form>
		<div class="form-group row">
			<div class="col-sm-3 text-center">
			</div>			  
			<div class="col-sm-3 text-center">
				<button type="button" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#exampleModal">保存修改</button>
			</div>
			<div class="col-sm-3 text-center">
				<button type="button" class="btn btn-primary" @click="goBack()">放弃修改</button>
			</div>
			<div class="col-sm-3 text-center">
			</div>			
		</div>	
					<!-- Modal -->
						<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
						  <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
						    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
						  </symbol>
						  <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
						    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
						  </symbol>
						  <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
						    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
						  </symbol>
						</svg>
						<div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
						  <div class="modal-dialog modal-dialog-centered">
						    <div class="modal-content">
						      <div class="modal-header alert-warning">
								<svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"/></svg>
						        <h5 class="modal-title" id="staticBackdropLabel">警告</h5>
						        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						      </div>
						      <div class="modal-body">
						        执行修改会覆盖原有数据，是否继续？
						      </div>
						      <div class="modal-footer">
								<button type="button" class="btn btn-warning col-sm-3" data-bs-dismiss="modal" @click="updatePlanOil()">是</button>
						        <button type="button" class="btn btn-secondary col-sm-3" data-bs-dismiss="modal">否</button>
						      </div>
						    </div>
						  </div>
						</div>
						<!--stop模态框 -->																																										
					</form>


	</div>
	<div class="col-1"></div>
</div>
<!--Stop Card -->

</template>

<script>
	import axios from 'axios';
	import router from '../../router/index.js';
	import store from '../../store/index.js';
	import {ref} from 'vue';
	let planOil=ref('');
	let htOil=ref('');
	
  export default {
	  name:'PlanOilDetail',
	  setup(){
			htOil=store.state.htOil;
			planOil=store.state.planOil;
			
//修改油品计划
			function updatePlanOil(){
				axios.post('/plan/updatePlanOilById',planOil).then(function (response) {
						console.log(response);
						alert("修改成功");
						planOil=response.data;
						router.push('/backHelp');
						})
						.catch(function (error) {
						console.log(error);
						alert("修改失败，连接错误！");
						});	
			}
			
//路由至新增出入库记录
			function toActualOilAdd(planOil){
				store.commit('setPlanOil',planOil);
				router.push({ name: 'ActualOilAdd'});
			}			
//返回油品计划列表
			function goBack(){
				router.push({ name: 'PlanOil'});
			}
					
		  return{
			  htOil,
			  planOil,
			  updatePlanOil,
			  goBack,
			  toActualOilAdd
		  }
	  }
  }
</script>

<style>
	
</style>
