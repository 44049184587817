<template>
<Head />
	
<div class="row">

<div class="col-2"></div>
<div class="col">
	<div class="row">
	<div class="col-sm-2"></div>
	  <div class="col-sm-8">
		<div class="card">
		  <div class="card-body">
			<h5 class="card-title text-center">用户登录</h5>
	<!-- 接包登录表单 -->
	
		
		  <div class="mb-3 row">
			<div class="col-sm-2"></div>  
		    <div class="col-sm-6"></div>
			<div class="col-sm-2"></div> 
		  </div>		
		  <div class="mb-3 row">
			<div class="col-sm-2"></div>  
		    <label for="staticEmail" class="col-sm-2 col-form-label">帐号</label>
		    <div class="col-sm-5">
		      <input type="text" class="form-control" id="userName" name="userName" v-model="user.userName">
		    </div>
			<div class="col-sm-3"></div> 
		  </div>
		  <div class="mb-3 row">
			<div class="col-sm-2"></div>   
		    <label for="inputPassword" class="col-sm-2 col-form-label">密码</label>
		    <div class="col-sm-5">
		      <input type="password" class="form-control" id="userPass" name="userPass" v-model="user.userPass">
		    </div>
			<div class="col-sm-3"></div>
		  </div>
<!-- 	  <div class="vstack gap-2 col-md-2 mx-auto">
		 <button class="btn btn-primary text-center" @click="login()">登录</button>
	  </div> -->
	  <div class="vstack gap-2 col-md-3 mx-auto">
		  <button class="btn btn-primary text-center " @click="login()">登录</button>
		  
		 <button class="btn btn-success text-center" @click="login2()">行转列、多表头演示</button>
	  </div>	  
	<!-- stop接包登录表单 -->
		  </div>
		</div>
	  </div>
	  <div class="col-sm-2"></div>
	</div>
</div>
<div class="col-2"></div>
</div>


</template>


<script>

	import {  reactive  } from 'vue'
	import router from '/src/router/index.js'
	import Head from '../Head.vue';
	import axios from 'axios';
	import store from '../../store/index.js';

	export default {
	  name:'Login',
	  components: {
		Head
	  },
	  setup(){
		  
		  let user=reactive({
			userId:'',
			userName:'admin',
			userPass:'admin'
		  });
		  // Object.keys(user).map(key => {
		  //   delete user[key]
		  // });
		  // Object.assign(user,store.state.user);
// 		  user=store.state.user;
// //用户登录
			const login= () =>{		
				axios.post('/user/login',user).then(function (response) {
					console.log(response);
					if(response.data.userName==null||response.data.userName==''){
						alert("登录失败，密码错误");
					}else{
						store.commit('setUser',response.data);
						store.commit('setUserName',user.userName);
						router.push('/admin');
					}
					})
					.catch(function (error) {
					console.log(error);
					alert("登录失败，请检查连接");
					});	
			}; 	
// //演示
			const login2= () =>{		
				router.push('/admin/tableTest');
			}; 				
		  return{
			  user,
			  login,
			  login2
		  }
	  }
  }


</script>

<style>
</style>
