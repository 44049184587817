<template>
	<div class="toast show" role="alert" aria-live="polite" data-bs-autohide="true" aria-atomic="true">
		<div role="alert" aria-live="assertive" aria-atomic="true">
			<div class="toast-header bg-success bg-opacity-25">
			  <svg class="alert-success bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
			  <strong class="me-auto text-dark">添加成功！</strong>
			  <small class="text-dark">刚刚</small>
			  <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
			</div>
			<div class="toast-body">
			  改项信息已成功添加。
			</div>
		</div>
	</div>

</template>

<script>
	export default{
		name:"SuccessToast"
	}
	
</script>

<style>
</style>
