<template>
	<Head />
	<!--头部导航栏 -->
	<div class="row">
		<div class="col-1"></div>
		<div class="col">
		<ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
		  <li class="nav-item" role="presentation">
		    <div class="btn-group" role="group" aria-label="Basic example">
		      <button type="button" class="btn btn-outline-dark" @click="back()">
				  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
				    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
				  </svg>
			  </button>
		      <button type="button" class="btn btn-outline-dark" @click="go()">
				  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
				    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
				  </svg>
			  </button>
		      <button type="button" class="btn btn-outline-dark" @click="reload()">
				  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
				    <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
				    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
				  </svg>
			  </button>
			  <button type="button" class="btn btn-outline-dark" @click="home()">
				  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
				    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
				  </svg>
			  </button>
		    </div>
		  </li>
&#12288;
		  <div class="vr"></div>
		  <li class="nav-item" role="presentation">
		    <button @click="toHtOil()" class="nav-link " id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">①管理提油合同</button>
		  </li>
		  <li class="nav-item" role="presentation">
		    <button @click="toPlanOil()" class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
				②管理提油计划
			</button>
		  </li>
		  <li class="nav-item" role="presentation">
		    <button @click="toActualOil()" class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">③管理出入库记录</button>
		  </li>
		  <div class="vr"></div>
		  <li class="nav-item" role="presentation">
		    <button @click="toCustomer()" class="nav-link " id="pills-home-tab2" data-bs-toggle="pill" data-bs-target="#pills-home2" type="button" role="tab" aria-controls="pills-home2" aria-selected="true">管理客商</button>
		  </li>
		  <div class="vr"></div>
		  <li class="nav-item" role="presentation">
		    <button @click="toAccount()" class="nav-link" id="pills-profile-tab2" data-bs-toggle="pill" data-bs-target="#pills-profile2" type="button" role="tab" aria-controls="pills-profile2" aria-selected="false">管理客商账务</button>
		  </li>
		</ul>
		</div>
		<div class="col-3">
		<div class="shadow-lg p-2 mb-3 bg-body rounded">欢迎：{{user.userName}}&#12288;现在是： {{ currentTime }}</div>
		</div>
		<!-- <div class="col-1"></div> -->
	</div>
	
	<div class="tab-content" id="pills-tabContent">
		<router-view />
	</div>
	<!--stop头部导航栏 -->
	<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
	  <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
		<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
	  </symbol>
	  <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
		<path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
	  </symbol>
	  <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
		<path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
	  </symbol>
	</svg>
	<div class="toast-container position-fixed bottom-0 end-0 p-3"  id="toastPlacement">
		<component :is="toast"></component>
	</div>	
</template>

<script>
	import Head from '../Head.vue';
	import router from '../../router/index.js'
	import {ref,watch} from 'vue';
	import store from '../../store/index.js';
	import SuccessToast from '../../components/commentcomponents/SuccessToast.vue';
	import FailToast from '../../components/commentcomponents/FailToast.vue';
	
	  export default {
		  name: 'Admin',
		  components: {
			Head,
			SuccessToast,
			FailToast
		  },
		  setup(){
			let toast=ref('');
			let currentTime=ref('');
			const user=store.state.user;
			const go= () =>{
				router.go(1);
			};
			const back= () =>{
				router.go(-1);
			};
			const reload= () =>{
				router.push('/backHelp');
			};
			const home= () =>{
				router.push('/admin');
			};
			const toHtOil= () =>{
				router.push('/admin/HtOil');
			};
			const toPlanOil= () =>{
				router.push('/admin/PlanOil');
			};			
			const toActualOil= () =>{
				router.push('/admin/ActualOil');
			};			
			const toCustomer= () =>{
				router.push('/admin/Customer');
			};
			const toAccount= () =>{
				router.push('/admin/Account');
			};
			
			watch(()=>store.state.successStatus,()=>{
				toast.value='SuccessToast';
				setTimeout(()=>{
				   toast.value='';
				},2500)
				
			}) 
			watch(()=>store.state.failStatus,()=>{
				toast.value='FailToast';
				  setTimeout(()=>{
				     toast.value='';
				  },2500)
			}) 	
					
			setInterval(()=>{
				currentTime.value=new Date().toLocaleString();
			},100)
		  	return{
		  		Head,
				go,
				back,
				reload,
				home,
				toHtOil,
				toPlanOil,
				toActualOil,
				toCustomer,
				toAccount,
				SuccessToast,
				FailToast,
				toast,
				user,
				currentTime
		  	}
		  }
	  }	
</script>

<style>
</style>
