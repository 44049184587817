<template>
<!--搜索框 -->  
<div class="alert alert-secondary" role="alert">
  <h4 class="alert-heading">管理客商</h4>
  <hr>
  <div class="row">
		<div class="col-1"></div>
		<div class="col-3">
			  <div class="input-group is-invalid">
			    <div class="custom-file">
			      <input type="text" class="form-control" id="searchText" v-model="searchText" placeholder="输入客商名称" @keyup.enter="search()">
			    </div>
			    <div class="input-group-append">
			       <button class="btn btn-outline-secondary" @click="search()" type="button">搜索</button>
			    </div>
			  </div>		
		</div>
		<div class="col-4"></div>
		<div class="col-4">
			<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop1">新增客商</button>
			<button class="btn btn-success m-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample12" aria-expanded="false" aria-controls="collapseExample12">
				Excel处理
			</button>
			<div class="collapse" id="collapseExample12">
			  <div class="card card-body">
				  <div class="mb-3">
				    <input class="form-control form-control-sm" id="formFileSm" type="file">
					<div class="">
						<label class="form-label">Excle导入</label>
						<button class="btn btn-warning m-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample12" aria-expanded="false" aria-controls="collapseExample12">
							导入
						</button>						
					</div>
				  </div>
				  <div role="separator" class="dropdown-divider"></div>
				  <div class="mb-3">
				    <label class="form-label">Excle导出</label>
					<a  class="btn btn-success m-1" href="http://localhost:8080/customer/downloadFile?fileName=CustomerList.xlsx">导出</a>
				  </div>				  
			  </div>
			</div>
		</div>
	 
  </div>
</div>
<!--stop搜索框 -->   

<!--模态框 -->  
<!-- Modal -->
<div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">新增客商</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form>
		  <div class="mb-3 row">
			<label for="inputPassword" class="col-sm-2 col-form-label">客商编码</label>
			<div class="col-sm-10">
        	  <input type="text" required class="form-control" id="customerId" name="customerId" v-model="customerA.customerId" >
			</div>
		  </div>
		  <div class="mb-3 row">
			<label for="inputPassword" class="col-sm-2 col-form-label">客商类型</label>
			<div class="col-sm-10">
        		<select class="form-control"  required id="customerType" name="customerType" v-model="customerA.customerType">
        			<option>客户</option>
        			<option>供应商</option>
        		</select>
			</div>
		  </div>
		  <div class="mb-3 row">
			<label for="inputPassword" class="col-sm-2 col-form-label">客商名称</label>
			<div class="col-sm-10">
        	  <input type="text" required class="form-control" id="customerName" name="customerName" v-model="customerA.customerName" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			</div>
		  </div>
        </form>
		
      </div>
      <div class="modal-footer">
		  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">关闭</button>
		  <button type="button" class="btn btn-primary" @click="addCustomer()" data-bs-dismiss="modal">确认添加</button>
      </div>
    </div>
  </div>
</div>
<!--stop模态框 -->

<!--表格-->	
	<table class="table table-hover">
		<thead class="sticky-top table-light">
		<tr> 
			<th>序号</th>
			<th>客商编码</th>
			<th>客商类型</th>
			<th>客商名称</th>
			<th>操作日志</th>
		</tr>
		</thead>
		<tbody> 
		  <tr v-for="(customer,index) in customerList" :key="customer" >
			<th scope="row">{{index+1}}</th>
			<td>
				<a class="text-primary" @click="updateCustomer(customer)">{{customer.customerId}}</a>
			</td>
			<td>
				{{customer.customerType}}
			</td>
			<td>
			  {{customer.customerName}}
			</td>
			<td>
			  {{customer.userName}}
			</td>
		  </tr>
		</tbody>	
	</table>
<!--stop表格-->	


<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>



</template>

<script>
	import axios from 'axios';
	import router from '../../router/index.js'
	import {ref,reactive} from 'vue';
	import store from '../../store/index.js';

		
	

	export default{
		name:'Customer',
		components:{
		},
		setup(){  
			let customerList=ref('');
			let searchText=ref('');
			let customer=reactive({});
			let customerA=ref('');
			customerA=store.state.customer;
			axios.get('/customer/getCustomerList')
			  .then(function (response) {
				console.log(response);
				customerList.value=response.data;
			  })
			  .catch(function (error) {
				console.log(error);
			  });				
			customer=store.state.customer;
			
//搜索客商			
			function search(){					
				axios.post('/customer/getCustomerListLike',searchText).then(function (response) {
						console.log(response);
						customerList.value=response.data;
						})
						.catch(function (error) {
						console.log(error);
						});	
			}
//新增客商信息
			function addCustomer(){
				axios.post('/customer/addCustomer',customerA).then(function (response) {
					console.log(response);
					store.commit('setSuccessStatus');
					router.push('/backHelp');
					})
					.catch(function (error) {
					store.commit('setFailStatus');
					console.log(error);
					});	
			}
//修改客商信息			
			const updateCustomer= (customer) =>{
				store.commit('setCustomer',customer);
				router.push({ name: 'CustomerDetail' });
			};			

			return{
				customerList,
				customer,
				searchText,
				search,
				addCustomer,
				updateCustomer,
				customerA
			}
		}
		
		


	}
</script>

<style>
</style>
