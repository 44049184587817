<template>
	wait
</template>

<script>
	import router from '../../router/index.js'
			  
	export default {
		  name:'BackHelp',
		  setup(){
			  // window.location.href="/admin/userManage";
			  // router.push('/admin/userManage');
			  router.go(-1);
			  return{
			  }
		  }
	}
</script>

<style>
	
</style>
