<template>
  <router-view/>
  <br>
  	<div class="footer" style="width:100%;
  	padding-top: 25px;
	height:100px;
  	background-color:#292C35;
  	color:#ffffff;
  	font-size:18px;
  	text-align:center;
  	line-height:50px;">
		Copyright © 2022 
  	</div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
