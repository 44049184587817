<template>
		<div class="toast show" role="alert" aria-live="polite" data-bs-autohide="true" aria-atomic="true">
			<div role="alert" aria-live="assertive" aria-atomic="true">
				<div class="toast-header bg-danger bg-opacity-25">
				  <svg class="alert-danger bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
				  <strong class="me-auto text-dark">添加失败！</strong>
				  <small class="text-dark">刚刚</small>
				  <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
				</div>
				<div class="toast-body">
				  添加失败,请检查表单或连接。
				</div>
			</div>
		</div>

</template>

<script>
	export default{
		name:"FailToast"
	}
</script>

<style>
</style>
