<template>
<!--搜索框 -->  
<div class="alert alert-secondary" role="alert">
  <h4 class="alert-heading">③管理出入库记录</h4>
  <hr>
  <div class="row">
		<div class="col-3 justify-content-center">
			<div class="input-group">
			  <input type="text" class="form-control" id="searchText" v-model="searchLike.searchText" @keyup.enter="search()">
			  <button type="button" class="btn btn-outline-secondary" @click="search()">{{searchLike.searchType}}</button>
			  <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
			    <span class="visually-hidden">Toggle Dropdown</span>
			  </button>
			</div>
			<div class="collapse" id="collapseExample">
			  <div class="card card-body">
					<a class="dropdown-item" @click="changeSearchType('检索客商信息')" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">检索客商信息</a>
				    <div role="separator" class="dropdown-divider"></div>
				    <a class="dropdown-item" @click="changeSearchType('检索合同编码')" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">检索合同编码</a>
			  </div>
			</div>
		</div>		  
	  
		<div class="col">
			<!-- 分页-->
			<div class="hstack gap-2 justify-content-center">
			    <div class="">
					<nav aria-label="Page navigation example">
					  <ul class="pagination ">
						<li class="page-item"><a class="page-link">每页显示：</a></li>
					    <li class="page-item">
							<select class="form-control" id="exampleFormControlSelect1" v-model="pageInfo.pageSize" @click="getActualOilPage()">
							  <option>3</option>
							  <option>5</option>
							  <option>10</option>
							  <option>50</option>
							  <option>1000</option>
							</select>
						</li>
					  </ul>	  
					</nav>
				</div>
			    <div class="">
					<nav aria-label="Page navigation example">
					  <ul class="pagination justify-content-center">
						<li class="page-item"><a class="page-link" href="#skip" @click="showFirstPage()">1</a></li>
						<li class="page-item">
						  <a class="page-link" href="#skip" aria-label="Previous" @click="showLastPage()">
							<span aria-hidden="true">&laquo;</span>
						  </a>
						</li>
						<li class="page-item active"><a class="page-link" >{{actualOilListPage.pageNum}}</a></li>
						<li class="page-item">
						  <a class="page-link" href="#skip" aria-label="Next" @click="showNextPage()">
							<span aria-hidden="true">&raquo;</span>
						  </a>
						</li>
						<li class="page-item"><a class="page-link" href="#skip" @click="showEndPage()">{{actualOilListPage.pages}}</a></li>
					  </ul>
					</nav>
				</div>
				<div class="">
					<nav aria-label="Page navigation example">
					  <ul class="pagination ">
					    <li class="page-item"><input type="number" class="form-control " v-model="pageInfo.pageNum" @keyup.enter="getActualOilPage()"></li>
					    <li class="page-item"><a class="page-link" href="#skip" @click="getActualOilPage()">Go!</a></li>
					  </ul>
					</nav>
				</div>
			</div>
			<!-- stop分页-->		
		</div>
		<div class="col-2">
			<button class="btn btn-success m-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample12" aria-expanded="false" aria-controls="collapseExample12">
				Excel处理
			</button>
			<div class="collapse" id="collapseExample12">
			  <div class="card card-body">
				  <div class="mb-3">
				    <input class="form-control form-control-sm" id="formFileSm" type="file">
					<div class="">
						<label class="form-label">Excle导入</label>
						<button class="btn btn-warning m-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample12" aria-expanded="false" aria-controls="collapseExample12">
							导入
						</button>						
					</div>
				  </div>
				  <div role="separator" class="dropdown-divider"></div>
				  <div class="mb-3">
				    <label class="form-label">Excle导出</label>
					<a class="btn btn-success m-1" href="http://localhost:8080/actual/downloadFile?fileName=ActualOilList.xlsx">导出</a>
				  </div>
			  </div>
			</div>
		</div>
  </div>

</div>
<!--stop搜索框 -->  

<!--模态框 -->  
  <div class="modal fade" id="exampleModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">新增出入库记录</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
			<form>
			  <div class="form-group row">
				<label for="inputPassword" class="col-sm-2 col-form-label">收发单号</label>
				<div class="col-sm-10">
				  <input type="text" class="form-control" id="ioNo" name="ioNo" v-model="ioNo" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
				</div>
			  </div>
			  <div class="form-group row">
				<label for="inputPassword" class="col-sm-2 col-form-label">计划号/提油号</label>
				<div class="col-sm-10">
				  <input type="text" class="form-control" id="planId" name="planId" v-model="planId" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
				</div>
			  </div>												
			  <div class="form-group row">
				<label for="inputPassword" class="col-sm-2 col-form-label">收发日期</label>
				<div class="col-sm-10">
				  <input type="text" class="form-control" id="ioDate" name="ioDate" v-model="ioDate" aria-label="Sizing example input" placeholder="示例:2001-08-01" aria-describedby="inputGroup-sizing-sm">
				</div>
			  </div>
			  <div class="form-group row">
				<label for="inputPassword" class="col-sm-2 col-form-label">收发车号</label>
				<div class="col-sm-10">
				  <input type="text" class="form-control" id="carNo" name="carNo" v-model="carNo" aria-label="Sizing example input" placeholder="示例:2001-08-01" aria-describedby="inputGroup-sizing-sm">
				</div>
			  </div>												
			  <div class="form-group row">
				<label for="inputPassword" class="col-sm-2 col-form-label">收发数量</label>
				<div class="col-sm-10">
				  <input type="text" class="form-control" id="ioNum" name="ioNum" v-model="ioNum" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
				</div>
			  </div>			  																  																  													  
			</form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">关闭</button>
          <button type="button" class="btn btn-primary" @click="addActualOil()" data-dismiss="modal">确认添加</button>
        </div>
      </div>
    </div>
</div>
<!--stop模态框 -->

<!--表格 -->	
<table class="table table-hover">
  <thead class="sticky-top table-light">
    <tr> 
		<th>序号</th>
		<th>收发单号</th>
	  	<th>类型</th>
	  	<th>合同编码</th>
	    <th>客商名称</th>
		<th>客商编码</th>
	    <th>单价</th>
	    <th>计划号/提油号</th>
	  	<th>收发日期</th>
	  	<th>收发车号</th>
	  	<th>收发数量</th>
	  	<th>总价</th>
	  	<th>合同备注</th>
		<th>操作日志</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(actualOil,index) in actualOilList" :key="actualOil" >
      <th scope="row">{{index+1}}</th>
      <td>
		<a class="text-primary" @click="updateActualOil(actualOil)">{{actualOil.ioNo}}</a>
      </td>
      <td>
		  {{actualOil.type}}
	  </td>
      <td>
		  {{actualOil.htId}}
	  </td>	 
      <td>
		  {{actualOil.customerName}}
	  </td>
      <td>
		  {{actualOil.customerId}}
	  </td>
      <td>
		  {{actualOil.oilPrice}}
	  </td>	
      <td>
		  {{actualOil.planId}}
	  </td>
      <td>
		  {{actualOil.ioDate}}
	  </td>
      <td>
		  {{actualOil.carNo}}
	  </td>
      <td>
		  {{actualOil.ioNum}}
	  </td>	
      <td>
		  {{actualOil.sum}}
	  </td>
      <td>
		  {{actualOil.remarks}}
	  </td>	
      <td>
		  {{actualOil.userName}}
	  </td>																														
    </tr>	  
  </tbody>
</table>
<!--stop表格 -->	

<!--分页 -->

<div class="hstack gap-2 justify-content-center">
	  <nav aria-label="Page navigation example">
	  		<ul class="pagination justify-content-center">
	  		<li class="page-item"><a class="page-link" href="#skip" @click="showFirstPage()">1</a></li>
	  		<li class="page-item">
	  		  <a class="page-link" href="#skip" aria-label="Previous" @click="showLastPage()">
	  			<span aria-hidden="true">&laquo;</span>
	  		  </a>
	  		</li>
	  		<li class="page-item active"><a class="page-link" >{{actualOilListPage.pageNum}}</a></li>
	  		<li class="page-item">
	  		  <a class="page-link" href="#skip" aria-label="Next" @click="showNextPage()">
	  			<span aria-hidden="true">&raquo;</span>
	  		  </a>
	  		</li>
	  		<li class="page-item"><a class="page-link" href="#skip" @click="showEndPage()">{{actualOilListPage.pages}}</a></li>
	  		</ul>
	  </nav>
	  <nav aria-label="Page navigation example">
	  		<ul class="pagination">
	  		  <li class="page-item"><input type="number" class="form-control" v-model="pageInfo.pageNum"></li>
	  		  <li class="page-item"><a class="page-link" href="#skip" @click="getActualOilPage()">Go!</a></li>
	  		</ul>
	  </nav>
</div>


<!--stop分页 -->
<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>

</template>

<script>
	import axios from 'axios';
	import router from '../../router/index.js'
	import {ref,reactive} from 'vue';
	import store from '../../store/index.js';
	
	
	export default{
		name:'ActualOil',
		components:{
			// router
		},
		setup(){  
			let actualOilList=ref('');
			let actualOil=ref('');
			let searchLike=reactive({
				searchText:'',
				searchType:'检索客商信息'
			})
			let actualOilPage=ref('');
			let actualOilListPage=ref('');
//分页
			let pageInfo=reactive({
				pageNum:1,
				pageSize:5,
				total:0,
				pages:0
			});		
			
			axios.post('/actual/getActualOilList',actualOil)
			  .then(function (response) {
				console.log(response);
				actualOilListPage.value=response.data;
				actualOilList.value=actualOilListPage.value.actualOilList;
			  })
			  .catch(function (error) {
				console.log(error);
			  });
			
			actualOil=store.state.actualOil;
			
//搜索合同			
			function search(){
				axios.post('/actual/getActualOilListLike',searchLike).then(function (response) {
						console.log(response);
						actualOilList.value=response.data;
						})
						.catch(function (error) {
						console.log(error);
						});	
			}
			const updateActualOil= (actualOil) =>{
				store.commit('setActualOil',actualOil);
				router.push({ name: 'ActualOilDetail' });
			};
//.....分页...........			
		const showFirstPage= () =>{
			pageInfo.pageNum=1;
			getActualOilPage();		  
		};	  
		const showLastPage= () =>{
			pageInfo.pageNum=actualOilListPage.value.pageNum-1;
			getActualOilPage();
		};
		const showEndPage= () =>{
			pageInfo.pageNum=actualOilListPage.value.pages;
			getActualOilPage();
		};		  
		const showNextPage= () =>{
			pageInfo.pageNum=actualOilListPage.value.pageNum+1;
			getActualOilPage();
		};
		const changeSearchType= (st) =>{
			  searchLike.searchType=st;
		  };
		  function getActualOilPage(){
		  	axios.post('/actual/getActualOilList',pageInfo)
			  .then(function (response) {
				console.log(response);
				actualOilListPage.value=response.data;
				actualOilList.value=actualOilListPage.value.actualOilList;
			  })
			  .catch(function (error) {
				console.log(error);
			  });
		  }
//.....stop分页..................				
			
//新增油品库存
			function addActualOil(){
				axios.post('/actual/addActualOil',actualOil).then(function (response) {
						console.log(response);
						alert("添加成功");
						router.push('/backHelp');
						})
						.catch(function (error) {
						alert("添加失败，连接错误。");
						console.log(error);
						});	
			}
			
			return{
				actualOilList,
				actualOil,
				search,
				addActualOil,
				updateActualOil,	
				changeSearchType,
//分页			
				pageInfo,
				actualOilPage,
				getActualOilPage,
				actualOilListPage,
				showNextPage,
				showEndPage,
				showLastPage,
				showFirstPage,
				searchLike				
			}
		}

	}
</script>

<style>
</style>
