import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/commentviews/Login.vue'
import Admin from '../views/adminviews/Admin.vue'
import BackHelp from '../components/commentcomponents/BackHelp.vue'
import Customer from '../components/admin/Customer.vue'
import HtOil from '../components/admin/HtOil.vue'
import PlanOil from '../components/admin/PlanOil.vue'
import ActualOil from '../components/admin/ActualOil.vue'
import HtOilDetail from '../components/admin/HtOilDetail.vue'
import HtOilAdd from '../components/admin/HtOilAdd.vue'
import PlanOilAdd from '../components/admin/PlanOilAdd.vue'
import ActualOilAdd from '../components/admin/ActualOilAdd.vue'
import TableTest from '../components/admin/TableTest.vue'
import PlanOilDetail from '../components/admin/PlanOilDetail.vue'
import ActualOilDetail from '../components/admin/ActualOilDetail.vue'
import CustomerDetail from '../components/admin/CustomerDetail.vue'
import Account from '../components/admin/Account.vue'
import AccountDetail from '../components/admin/AccountDetail.vue'


const routes = [
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
  	children: [
		  {
		    path: 'customer',
			name: 'Customer',
		    component: Customer
		  },
		  {
		    path: 'htOil',
			name: 'HtOil',
		    component: HtOil
		  },
		  {
		    path: 'planOil',
			name: 'PlanOil',
		    component: PlanOil
		  },
		  {
		    path: 'actualOil',
			name: 'ActualOil',
		    component: ActualOil
		  },		  
		  {
		    path: 'htOilDetail',
			name: 'HtOilDetail',
		    component: HtOilDetail
		  },
		  {
		    path: 'htOilAdd',
			name: 'HtOilAdd',
		    component: HtOilAdd
		  },
		  {
		    path: 'planOilAdd',
			name: 'PlanOilAdd',
		    component: PlanOilAdd
		  },
		  {
		    path: 'actualOilAdd',
			name: 'ActualOilAdd',
		    component: ActualOilAdd
		  },							  
		  {
		    path: 'planOilDetail',
			name: 'PlanOilDetail',
		    component: PlanOilDetail
		  },
		  {
		    path: 'actualOilDetail',
			name: 'ActualOilDetail',
		    component: ActualOilDetail
		  },
		  {
		    path: 'tableTest',
		  			name: 'TableTest',
		    component: TableTest
		  },
		  {
		    path: 'customerDetail',
			name: 'CustomerDetail',
		    component: CustomerDetail
		  },
		  {
		    path: 'Account',
			name: 'Account',
		    component: Account
		  },
		  {
		    path: 'AccountDetail',
			name: 'AccountDetail',
		    component: AccountDetail
		  }												
																	  
  	    ]
  },
  {
    path: '/backHelp',
    name: 'BackHelp',
    component: BackHelp
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
