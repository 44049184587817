<template>
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><router-link to="/admin/HtOil">管理提油合同</router-link></li>
    <li class="breadcrumb-item active" aria-current="page">合同详情</li>
	

	
  </ol>
</nav>
<div class="alert alert-primary" role="alert" >
	合同详情
</div>	



<!--Card -->
<div class="row">
	<div class="col-1"></div>
	<div class="col">
		<!-- 表格 -->
		<table class="table table-bordered">
		  <tbody>
		    <tr>
		      <th scope="row">合同编码</th>
		      <td>
				  <input type="text" readonly class="form-control" id="htId" name="htId" v-model="htOil.htId" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
		      <th scope="row">合同类型</th>
		      <td>
				  <input type="text" readonly class="form-control" id="htType" name="htType" v-model="htOil.htType" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
		    </tr>
		    <tr>
		      <th scope="row">客商名称</th>
		      <td>
				  <input type="text" readonly class="form-control" id="customerName" name="customerName" v-model="htOil.customerName"  aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
		      <th scope="row">合同生效日期</th>
		      <td>
				  <input type="date" readonly class="form-control" name="activationDate" v-model="htOil.activationDate" >
			  </td>
		    </tr>
		    <tr>
		      <th scope="row">合同终止日期</th>
		      <td>
				  <input type="date" readonly class="form-control" id="stopDate" name="stopDate" v-model="htOil.stopDate" >
			  </td>
		      <th scope="row">物料名称</th>
		      <td>
				  <input type="text" readonly class="form-control" id="materialName" name="materialName" v-model="htOil.materialName">
			  </td>
		    </tr>			
		    <tr>
		      <th scope="row">油品规格</th>
		      <td>
				<input type="text"  class="form-control" id="oilSpecification" name="oilSpecification" v-model="htOil.oilSpecification" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
		      <th scope="row">发货油库</th>
		      <td>
				  <select class="form-control" id="outputOd" v-model="htOil.outputOd" >
				  	<option>本库</option>
				  	<option>外库</option>
				  </select>
			  </td>
		    </tr>	
		    <tr>
		      <th scope="row">提货油库</th>
		      <td>
				  <input type="text"  class="form-control" id="getOd" name="getOd" v-model="htOil.getOd" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
		      <th scope="row">结算单价</th>
		      <td>
				  <input type="text"  class="form-control" id="oilPrice" name="oilPrice" v-model="htOil.oilPrice"  aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
		    </tr>	
		    <tr>
		      <th scope="row">合同数量</th>
		      <td>
				  <input type="text"  class="form-control" id="htNum" name="htNum" v-model="htOil.htNum" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
		      <th scope="row">业务员</th>
		      <td>
				  <input type="text"  class="form-control" id="salesman" name="salesman" v-model="htOil.salesman" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
		    </tr>
		    <tr>
		      <th scope="row">油品收付款协议</th>
		      <td>
				  <select class="form-control" id="agreement1" name="agreement1" v-model="htOil.agreement" >
				    <option>先货后款</option>
				  	<option>先款后货(全款支付)</option>
				  	<option>先款后货(款到拆单)</option>
				  	<option>先款后货(款到放行)</option>
				  </select>
			  </td>
		      <th scope="row">保证金比例</th>
		      <td>
				  <input type="text"  class="form-control" id="bondRate" name="bondRate" v-model="htOil.bondRate" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
		    </tr>													
		    <tr>
		      <th scope="row">运输方式名称</th>
		      <td>
				  <select class="form-control" id="transportName" name="transportName" v-model="htOil.transportName" >
				  	<option>公司车运输</option>
				  	<option>客户车自提</option>
				  	<option>公司船运输</option>
				  	<option>客户船自提</option>
				  </select>
			  </td>
		      <th scope="row">已建计划数：<span class="badge badge-primary badge-pill">**个</span></th>
			  <td>
				<button type="button" class="btn btn-primary" @click="toPlanOilAdd(htOil)">新建计划</button>
			  </td>
		    </tr>																
		    <tr>
		      <th scope="row">合同备注</th>
		      <td colspan="3">
				  <textarea class="form-control" id="remarks" name="remarks" v-model="htOil.remarks" rows="5"></textarea>
			  </td>
		    </tr>
			<tr class="table-secondary">
			  <th scope="row">已安排计划量</th>
			  <td>
				  <input type="text" readonly class="form-control" id="arrangePlan" name="arrangePlan" v-model="htOil.arrangePlan" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
			  <th scope="row">已发货量</th>
			  <td>
				  <input type="text" readonly class="form-control" id="actualOutput" name="actualOutput" v-model="htOil.actualOutput" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
			</tr>		
			<tr class="table-secondary">
			  <th scope="row">已安排未发货量</th>
			  <td>
				  <input type="text" readonly class="form-control" id="arrangeWaitDelive" name="arrangeWaitDelive" v-model="htOil.arrangeWaitDelive" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
			  <th scope="row">剩余可安排量</th>
			  <td>
				  <input type="text" readonly class="form-control" id="remainPlan" name="remainPlan" v-model="htOil.remainPlan" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
			</tr>		
			<tr class="table-secondary">
			  <th scope="row">剩余未出库量</th>
			  <td>
				  <input type="text" readonly class="form-control" id="remainOut" name="remainOut" v-model="htOil.remainOut" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
			  <th scope="row">操作员</th>
			  <td>
				  <input type="text" readonly class="form-control" id="userName" name="userName" v-model="htOil.userName" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
			  </td>
			</tr>				
			
		  </tbody>
		</table>
		
		<!--stop表格-->
		
			<form>																																										
		  <div class="form-group row">
		    <div class="col-sm-3 text-center">
		    </div>			  
		    <div class="col-sm-3 text-center">
			  <button type="button" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#exampleModal">保存修改</button>
		    </div>
			<div class="col-sm-3 text-center">
			    <button type="button" class="btn btn-primary" @click="goBack()">放弃修改</button>
			</div>
			<div class="col-sm-3 text-center">
			</div>			
		  </div>	
			<!-- Modal -->
				<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
				  <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
				    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
				  </symbol>
				  <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
				    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
				  </symbol>
				  <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
				    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
				  </symbol>
				</svg>
				<div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
				  <div class="modal-dialog modal-dialog-centered">
				    <div class="modal-content">
				      <div class="modal-header alert-warning">
						<svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"/></svg>
				        <h5 class="modal-title" id="staticBackdropLabel">警告</h5>
				        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				      </div>
				      <div class="modal-body">
				        执行修改会覆盖原有数据，是否继续？
				      </div>
				      <div class="modal-footer">
						<button type="button" class="btn btn-warning col-sm-3" data-bs-dismiss="modal" @click="updateHtOil()">是</button>
				        <button type="button" class="btn btn-secondary col-sm-3" data-bs-dismiss="modal">否</button>
				      </div>
				    </div>
				  </div>
				</div>
				<!--stop模态框 -->																																										
			</form>

	</div>
	<div class="col-1"></div>
</div>
<!--Stop Card -->

</template>

<script>
	import axios from 'axios';
	import router from '../../router/index.js';
	import store from '../../store/index.js';
	import {reactive} from 'vue';
	
	
	
  export default {
	  name:'HtOilDetail',
	  setup(){
			let user=reactive({
				// userId:'',
				// userName:'',
				// userPass:''
			});
			let htOil=reactive({
				// htId:'',
				// customerId:'',
				// htType:'',
				// customerName:'',
				// activationDate:'',
				// stopDate:'',
				// materialName:'',
				// oilSpecification:'',
				// outputOd:'',
				// getOd:'',
				// oilPrice:'',
				// htNum:'',
				// salesman:'',
				// agreement:'',
				// bondRate:'',
				// transportName:'',
				// remarks:'',
				// arrangePlan:0,
				// actualOutput:0,
				// arrangeWaitDelive:0,
				// remainPlan:0,
				// remainOut:0,
				// pageNum:1,
				// pageSize:20,
				// pages:1,
				// userName:store.state.userName
			});
			Object.assign(user,store.state.user);
			initHtOil();
					
//修改油品合同
			function initHtOil(){
				Object.assign(htOil,store.state.htOil);
				axios.post('/ht/getHtOilById',htOil).then(function (response) {
					console.log(response);
					store.commit('setHtOil',response.data);
					Object.assign(htOil,response.data);
					})
					.catch(function (error) {
					console.log(error);
					});	
			}
//修改油品合同
			function updateHtOil(){
				htOil.userName=store.state.userName;
				axios.post('/ht/updateHtOilById',htOil).then(function (response) {
					console.log(response);
					alert("修改成功");
					initHtOil();
					// store.commit('setHtOil',response.data);
					// Object.assign(htOil,response.data);
					router.push('/backHelp');
					})
					.catch(function (error) {
					console.log(error);
					alert("修改失败，连接错误！");
					});	
			}
//路由至新增计划页面			
			function toPlanOilAdd(htOil){
				store.commit('setHtOil',htOil);
				router.push({ name: 'PlanOilAdd' });
			}
//返回上一层
			function goBack(){
				router.push({ name: 'HtOil'});
			}
					
		  return{
			  htOil,
			  updateHtOil,
			  goBack,
			  toPlanOilAdd,
			  user,
			  initHtOil
		  }
	  }
  }
</script>

<style>
	
</style>
