<template>
	<!-- <div class="alert alert-primary" role="alert"> -->

		<nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
		  <ol class="breadcrumb">
			  &#12288;&#12288;
		    <li class="breadcrumb-item"><router-link to="/admin/Account" class="alert-link">管理客商账务</router-link></li>
		    <li class="breadcrumb-item active" aria-current="page">客商账务明细</li>
		  </ol>
		</nav>

	<!-- </div> -->


				  <div class="offcanvas offcanvas-bottom" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
				    <form>
					<div class="offcanvas-header">
				      <h5 class="offcanvas-title" id="offcanvasScrollingLabel">新增客商账务</h5>
				      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" @click="cleanData()"></button>
				    </div>
				    <div class="offcanvas-body">
						<div class="row g-3 was-validated">
							  <div class="col-md">
								<div class="form-floating">
								  <select class="form-select" required id="fundType"  v-model="account.fundType">
<!-- 									<option value="收货">收货+</option>
									<option value="发货">发货-</option> -->
									<option value="收款">(充值)收款-</option>
									<option value="付款">(退款)付款+</option>
								  </select>
								  <label for="floatingSelectGrid">收付款类型</label>
								</div>
							  </div>
							  <div class="col-md">
								<div class="form-floating">
								  <input type="number" step="0.01" min="0.01" required v-model="account.amount" class="form-control" id="amount" placeholder="Password">
								  <label for="floatingInputGrid" >金额(绝对值)</label>
								</div>
							  </div>
							  <div class="col-md">
								<div class="form-floating">
								  <input type="datetime-local" required v-model="account.tradeDate" class="form-control" id="tradeDate" placeholder="Password">
								  <label for="floatingInputGrid" >交易时间</label>
								</div>
							  </div>
							</div>
							<br>
						<div class="row g-3 was-validated">
							  <div class="col-md">
								  <div class="form-floating">
								    <textarea class="form-control" v-model="account.remarks" placeholder="备注" id="remarks"></textarea>
								    <label for="floatingTextarea2">备注</label>
								  </div>
							  </div>
							  
							  <button type="button" class="btn col-sm-1 btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal" @click="addAccount()">确认新增</button>
							  &#12288;
							  <button type="button" class="btn col-sm-1 btn-secondary" @click="cleanData()">清空内容</button>
						</div>							
							
				    </div>
					</form>
				  </div>

<!--Card -->
<div class="row">
	<div class="col"></div>
	<div class="col-11">
		<!-- 表格 -->
		<table class="table table-bordered ">
		  <thead class="table-dark">
		    <tr>
		      <th scope="col">客商编码</th>
			  <th scope="col">客商类型</th>
		      <th scope="col">客商名称</th>
		      <th scope="col">余额</th>
			  <th scope="col">操作</th>
		    </tr>
		  </thead>
		  <tbody>	
			<tr>
				<td>{{accountView.customerId}}</td>
				<td>{{accountView.customerType}}</td>
				<td>{{accountView.customerName}}</td>
				<td>{{accountView.balance}}</td>
				<td>
					<button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" @click="cleanData()">新增客商账务</button>
				</td>
			</tr>
			
			<tr>
			  <td colspan="6">
			    <table class="table table-sm table-borderless sticky-top">
			      <thead class="table-secondary">
			    	<tr>
			    	  <th scope="col">#</th>
			    	  <th scope="col">账单流水号</th>
			    	  <th scope="col">类型</th>
					  <th scope="col">车牌号</th>
					  <th scope="col">数量</th>
					  <th scope="col">单价</th>
			    	  <th scope="col">金额</th>
			    	  <th scope="col">交易时间</th>
			    	  <th scope="col">备注</th>
					  <th scope="col">操作</th>
					  <th scope="col">操作日志</th>
			    	</tr>
			      </thead>
			      <tbody v-for="(ac,index) in accountList" :key="ac">
					  <tr class="table-danger" v-if="ac.amount>0">
						  <th scope="row">{{index+1}}</th>
						  <td>{{ac.accountId}}</td>
						  <td>{{ac.fundType}}</td>
						  <td>{{ac.carNo}}</td>
						  <td>{{ac.ioNum}}</td>
						  <td>{{ac.oilPrice}}</td>
						  <td>{{ac.amount}}</td>
						  <td>{{ac.tradeDate}}</td>
						  <td>{{ac.remarks}}</td>
						  <td>{{ac.userName}}</td>
						  <td class="text-start align-baseline">
							  <span class="m-1">|</span>
							  <span v-if="ac.fundType=='收款'||ac.fundType=='付款'" @click="showUpdate(ac)" class="badge m-1 rounded-pill bg-warning text-dark" data-bs-toggle="modal" data-bs-target="#staticBackdrop2">修改</span>
							  <span v-if="ac.fundType=='收款'||ac.fundType=='付款'" @click="showUpdate(ac)" class="badge m-1 rounded-pill bg-danger" data-bs-toggle="modal" data-bs-target="#staticBackdrop3">删除</span>
						  </td>
					  </tr>
					  <tr class="table-success" v-if="ac.amount<=0">
						  <th scope="row">{{index+1}}</th>
						  <td>{{ac.accountId}}</td>
						  <td>{{ac.fundType}}</td>
						  <td>{{ac.carNo}}</td>
						  <td>{{ac.ioNum}}</td>
						  <td>{{ac.oilPrice}}</td>
						  <td>{{ac.amount}}</td>
						  <td>{{ac.tradeDate}}</td>
						  <td>{{ac.remarks}}</td>
						  <td>{{ac.userName}}</td>
						  <td class="text-start align-baseline">
							  <span class="m-1">|</span>
							  <span v-if="ac.fundType=='收款'||ac.fundType=='付款'" @click="showUpdate(ac)" class=" badge m-1 rounded-pill bg-warning text-dark" data-bs-toggle="modal" data-bs-target="#staticBackdrop2">修改</span>
							  <span v-if="ac.fundType=='收款'||ac.fundType=='付款'" @click="showUpdate(ac)" class="badge m-1 rounded-pill bg-danger" data-bs-toggle="modal" data-bs-target="#staticBackdrop3">删除</span>
						  </td>
					  </tr>
			      </tbody>
			    </table>
			    
			  </td>
			</tr>
		  </tbody>
		</table>

		<!--stop表格-->

<!-- Modal -->
<div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header bg-warning">
        <h5 class="modal-title" id="staticBackdropLabel">修改</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">账单流水号</label>
            <input type="text" readonly class="form-control" id="accountId" v-model="account.accountId" aria-describedby="emailHelp">
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">类型</label>
            <input type="text" readonly class="form-control" v-model="account.fundType" id="fundType">
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">金额</label>
            <input type="text" class="form-control" v-model="account.amount" id="amount">
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">交易时间</label>
            <input type="datetime-local" class="form-control" v-model="account.tradeDate" id="tradeDate">
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">备注</label>
            <input type="text" class="form-control" v-model="account.remarks" id="remarks">
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal" @click="updateAccount()">确认覆盖</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header bg-danger">
        <h5 class="modal-title" id="staticBackdropLabel3">删除</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">账单流水号</label>
            <input type="text" readonly class="form-control" id="accountId" v-model="account.accountId" aria-describedby="emailHelp">
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">类型</label>
            <input type="text" readonly class="form-control" v-model="account.fundType" id="fundType">
          </div>
		  <div class="input-group mb-3">
		    <span class="input-group-text" id="basic-addon1">@</span>
		    <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
		  </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">金额</label>
            <input type="text" readonly class="form-control" v-model="account.amount" id="amount">
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">交易时间</label>
            <input type="datetime-local" readonly class="form-control" v-model="account.tradeDate" id="tradeDate">
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">备注</label>
            <input type="text" readonly class="form-control" v-model="account.remarks" id="remarks">
          </div>							  
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteAccount()">已核对，确认删除</button>
      </div>
    </div>
  </div>
</div>


	</div>
	<div class="col"></div>
</div>
<!--Stop Card -->




<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>

</template>

<script>
	import axios from 'axios';
	import router from '../../router/index.js';
	import store from '../../store/index.js';
	import {ref} from 'vue';
	
  export default {
	  name:'AccountDetail',
	  setup(){
			let account=ref({
			   accountId:'',
			   customerId:'',
			   fundType:'',
			   carNo:'',
			   ioNum:'',
			   oilPrice:'',
			   amount:'',
			   tradeDate:'',
			   remarks:'',
			   pageNum:1,
			   pageSize:20
			});
			
			let accountView=ref('');
			let accountList=ref('');
			let customer=store.state.customer;
			account=store.state.account;
			axios.post('/account/getAccountDetailByCustomerId',customer).then(function (response) {
					console.log(response);
						accountList.value=response.data;
					})
					.catch(function (error) {
					console.log(error);
					});	
			accountView.value=store.state.accountView;
			getAccountById();

//清空内容
			function getAccountById(){
				axios.post('/account/getAccountById',customer).then(function (response) {
						console.log(response);
							accountView.value=response.data;
						})
						.catch(function (error) {
						console.log(error);
						});	
			}
//清空内容
			function cleanData(){
				    Object.keys(account).map(key => {
				      delete account[key]
				    })
			}

//展示单条账单信息
			const showUpdate= (ac) =>{
				cleanData();
				Object.assign(account,ac);
			}
//更新账单信息
			const updateAccount= () =>{
				axios.post('/account/updateAccount',account).then(function (response) {
						console.log(response);
							alert("修改成功");
							getAccountList();
						})
						.catch(function (error) {
						console.log(error);
							alert("修改失败,连接错误");
						});
			}
//删除账单信息
			const deleteAccount= () =>{
				axios.post('/account/deleteAccount',account).then(function (response) {
						console.log(response);
							alert("删除成功");
							getAccountList();
						})
						.catch(function (error) {
						console.log(error);
							alert("删除失败,连接错误");
						});
			}			
			
//获取账单信息			
			const getAccountList= () =>{
				axios.post('/account/getAccountDetailByCustomerId',customer).then(function (response) {
						console.log(response);
							accountList.value=response.data;
						})
						.catch(function (error) {
						console.log(error);
						});
			};
//新增账单信息			
			const addAccount= () =>{
				Object.assign(account,{customerId:accountView.value.customerId});
				// account.value.customerId=accountView.value.customerId;
				axios.post('/account/addAccount',account).then(function (response) {
						console.log(response);
						alert("添加成功");
						getAccountList();
						getAccountById();
						cleanData();
						})
						.catch(function (error) {
						console.log(error);
						alert("添加失败，连接错误。");
						});	
			};			
//返回账务列表
			function goBack(){
				router.push({ name: 'Account'});
			}
					
		  return{
			  account,
			  accountView,
			  customer,
			  accountList,
			  goBack,
			  cleanData,
			  getAccountList,
			  addAccount,
			  getAccountById,
			  showUpdate,
			  updateAccount,
			  deleteAccount
		  }
	  }
  }
</script>

<style>
	
</style>
