import { createStore } from 'vuex'

export default createStore({
  state: {
	  overallOutlineId:'0',
	  backHelpState:'1',
	  userName:'',
	  goalNumber:0,
	  wrongBook:'1',
	  outlineName:'......',
	  currentNumber:-1,
	  totalNumber:0,
	  myPass:0,
	  myWrong:0,
	  outlineId:'0',
	  examinationId:'0',
	  goalProgress:'',
	  pageNum:1,
	  pageSize:20,
	  style:0,
	  htOil: {
	  	htId:'',
		customerId:'',
		htType:'',
		customerName:'',
		activationDate:'',
		stopDate:'',
		materialName:'',
		oilSpecification:'',
		outputOd:'',
		getOd:'',
		oilPrice:'',
		htNum:'',
		salesman:'',
		agreement:'',
		bondRate:'',
		transportName:'',
		remarks:'',
		arrangePlan:0,
		actualOutput:0,
		arrangeWaitDelive:0,
		remainPlan:0,
		remainOut:0,
		pageNum:1,
		pageSize:20,
		pages:1,
		userName:''
	  },
	  htOilList: [],	
		planOilList: [],
		actualOilList: [],
	  planOil: {
		planId:'',
		htId:'',
		customerName:'',
		customerId:'',
		materialName:'',
		oilPrice:'',
		htNum:'',
		planDate:'',
		planNum:'',
		getNum:'',
		remaindeNum:'',
		type:'',
		pageNum:1,
		pageSize:20,
		pages:1,
		userName:''
	  },
	  actualOil: {
		ioNo:'',
		type:'',
		htId:'',
		customerName:'',
		customerId:'',
		planNum:'',
		oilPrice:'',
		planId:'',
		ioDate:'',
		carNo:'',
		ioNum:'',
		sum:'',
		remarks:'',
		pageNum:1,
		pageSize:20,
		pages:1,
		userName:''
	  },
	  customer: {
		customerId:'',
		customerType:'',
		customerName:'',
		pageNum:1,
		pageSize:20,
		userName:''
	  },
	  accountView: {
		customerId:'',
		customerType:'',
		customerName:'',
		balance:'',
		pageNum:1,
		pageSize:20,
		userName:''
	  }	,
	  account: {
		accountId:'',
		customerId:'',
		fundType:'',
		amount:'',
		tradeDate:'',
		remarks:'',
		pageNum:1,
		pageSize:20,
		userName:''
	  }	,
	  user: {
		    userId:'',
			userName:'',
	  		userPass:''
	  }	,
	  successStatus:1,
	  failStatus:1
  },
  
  mutations: {
	  setSuccessStatus(state){
		  if(state.successStatus<=0){
			  state.successStatus++;
		  }
		  else{
			  state.successStatus--;
		  }
	  },
	  setFailStatus(state){
		  if(state.failStatus<=0){
			  state.failStatus++;
		  }
		  else{
			  state.failStatus--;
		  }
	  },
	  setUser(state,user){
	  		  state.user=user;
			  
	  },
	  setUserName(state,userName){
		  state.userName=userName;
	  },
	  setStyle(state,style){
	  		  state.style=style;
	  },
	  setHtOil(state,htOil){
			// Object.assign(state.htOil,htOil);
	  		  state.htOil=htOil;
	  },
	  setHtOilUserName(state,userName){
			  state.user.userName=userName;
	  },
	  setHtOilList(state,htOilList){
	  		  state.htOilList=htOilList;
	  },
	  setPlanOil(state,planOil){
	  		  state.planOil=planOil;
	  },
	  setPlanOilList(state,planOilList){
	  		  state.planOilList=planOilList;
	  },
	  setActualOil(state,actualOil){
	  		  state.actualOil=actualOil;
	  },
	  setActualOilList(state,actualOilList){
	  		  state.actualOilList=actualOilList;
	  },
	  setCustomer(state,customer){
	  		  state.customer=customer;
	  },
	  setAccount(state,account){
	  		  state.account=account;
	  },
	  setAccountView(state,accountView){
	  		  state.accountView=accountView;
	  },										  
	  initReciting(state){
	  		  state.myPass=0;
			  state.myWrong=0;
			  state.currentNumber=-1;
			  // state.myPass=0;
	  },	  
	  changebackHelpState(state,stateValue){
	  		  state.backHelpState=stateValue
	  },
	  recordUserName(state,userName){
	  	  state.userName=userName
	  },
	  changeGoalNumber(state,goalNumber){
		  state.goalNumber=goalNumber
	  },
	  changeTotalNumber(state,totalNumber){
	  		  state.totalNumber=totalNumber
	  },
	  subTotalNumber(state,totalNumber){
	  		  state.totalNumber=state.totalNumber-totalNumber;
			  state.currentNumber++;
			  state.goalProgress="width:"+state.currentNumber/state.goalNumber*100+"%";
	  },
	  addmyPass(state,myPass){
	  		  state.myPass=state.myPass+myPass
	  },
	  addmyWrong(state,myWrong){
	  		  state.myWrong=state.myWrong+myWrong
	  },			
	  
	  changeWrongBook(state,wrongBook){
		  state.wrongBook=wrongBook
	  },
	  changeOutlineName(state,outlineName){
				  state.outlineName=outlineName
	  },
	  changeCurrentNumber(state,currentNumber){
		  state.currentNumber=currentNumber
	  },
	  changeMyPass(state,myPass){
		  state.myPass=myPass
	  },
	  changeMyWrong(state,MyWrong){
		  state.MyWrong=MyWrong
	  },
	  changeOutlineId(state,outlineId){
		  state.outlineId=outlineId
	  },
	  changeExaminationId(state,examinationId){
		  state.examinationId=examinationId
	  },
	  changePageNum(state,pageNum){
		  state.pageNum=pageNum
	  },
	  changePageSize(state,pageSize){
		  state.pageSize=pageSize
	  },												
  },
  actions: {
  },
  modules: {
  }
})
