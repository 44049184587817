<template>
<!--搜索框 -->  
<div class="alert alert-secondary" role="alert">
  <h4 class="alert-heading">管理客商账务</h4>
  <hr>
  <div class="row">
		<div class="col-1"></div>
		<div class="col-3">
			  <div class="input-group is-invalid">
			    <div class="custom-file">
			      <input type="text" class="form-control" id="searchText" v-model="searchText" placeholder="输入客商名称" @keyup.enter="search()">
			    </div>
			    <div class="input-group-append">
			       <button class="btn btn-outline-secondary" @click="search()" type="button">搜索</button>
			    </div>
			  </div>		
		</div>
		<div class="col-4"></div>
		<div class="col-4">
				<button class="btn btn-success m-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample12" aria-expanded="false" aria-controls="collapseExample12">
					Excel处理
				</button>
				<div class="collapse" id="collapseExample12">
				  <div class="card card-body">
					  <div class="mb-3">
					    <input class="form-control form-control-sm" id="formFileSm" type="file">
						<div class="">
							<label class="form-label">Excle导入</label>
							<button class="btn btn-warning m-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample12" aria-expanded="false" aria-controls="collapseExample12">
								导入
							</button>						
						</div>
					  </div>
					  <div role="separator" class="dropdown-divider"></div>
					  <div class="mb-3">
					    <label class="form-label">Excle导出</label>
						<a  class="btn btn-success m-1" href="http://localhost:8080/account/downloadFile?fileName=AccountList.xlsx">导出</a>
					  </div>				  
				  </div>
				</div>
			<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">新增客商账务</button> -->
		</div>
	 
  </div>
</div>
<!--stop搜索框 -->   

<!--表格-->	
	<table class="table table-hover">
		
		<thead class="sticky-top table-light">
		<tr> 
			<th>#</th>
			<th>客商编码</th>
			<th>客商类型</th>
			<th>客商名称</th>
			<th>余额</th>
			<th>操作日志</th>
		</tr>
		</thead>
		<tbody> 
		  <tr v-for="(accountView,index) in accountViewList" :key="accountView" @click="toAccountDetail(accountView)">
			<th scope="row">{{index+1}}</th>
			<td>
			  {{accountView.customerId}}
			</td>			
			<td>
			  {{accountView.customerType}}
			</td>
			<td>
			  {{accountView.customerName}}
			</td>
			<td>
			  {{accountView.balance}}
			</td>
			<td>
			  {{accountView.userName}}
			</td>
		  </tr>
		</tbody>	
	</table>
<!--stop表格-->	

<!--分页 -->

<!--stop分页 -->
<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>

</template>

<script>
	import axios from 'axios';
	import router from '../../router/index.js'
	import {ref} from 'vue';
	import store from '../../store/index.js';


	export default{
		name:'Account',
		components:{
		},
		setup(){  
			let accountViewList=ref('');
			let searchText=ref('');
			let accountView=ref('');
			
			axios.get('/account/getAccountList')
			  .then(function (response) {
				console.log(response);
				accountViewList.value=response.data;
			  })
			  .catch(function (error) {
				console.log(error);
			  });	
			
			accountView=store.state.accountView;
//搜索客商
			function search(){					
				axios.post('/account/getAccountListLike',searchText).then(function (response) {
						console.log(response);
						accountViewList.value=response.data;
						})
						.catch(function (error) {
						console.log(error);
						});	
			}

//修改客商信息
			const toAccountDetail= (customer) =>{
				store.commit('setCustomer',customer);
				store.commit('setAccountView',customer);
				router.push({ name: 'AccountDetail' });
			};

			return{
				accountViewList,
				accountView,
				searchText,
				search,
				toAccountDetail
			}
		}
		
	}
</script>

<style>
</style>
