<template>
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><router-link to="/admin/ActualOil">管理出入库记录</router-link></li>
    <li class="breadcrumb-item active" aria-current="page">新增出入库记录</li>
  </ol>
</nav>
<div class="alert alert-success" role="alert" >
	新增出入库记录
</div>	

<!--Card -->
<div class="row was-validated">
	<div class="col-1"></div>
	<div class="col">
		<!-- 表格 -->
		<table class="table table-striped">
		  <thead class="table-light">
		    <tr>  <!--显示列表栏-->	
		  		<th scope="col">合同编码</th>
		  		<th scope="col">合同类型</th>
		  		<th scope="col">客商名称</th>
		  		<th scope="col">发起时间</th>
		  		<th scope="col">终止时间</th>
		  		<th scope="col">物料名称</th>
		  	    <th scope="col">油品规格</th>
		  	    <th scope="col">发货油库</th>
		  	    <th scope="col">提货油库</th>
		  	    <th scope="col">结算单价</th>
		  	    <th scope="col">合同数量</th>
		  	    <th scope="col">已安排计划量</th>
		  	    <th scope="col">已发货量</th>
		  	    <th scope="col">已安排未发货量</th>
		  	    <th scope="col">姓名</th>
		  	    <th scope="col">油品收款协议</th>
		  	    <th scope="col">保证金比例</th>
		  	    <th scope="col">运输方式名称</th>
		  	    <th scope="col">剩余可安排量</th>
		  	    <th scope="col">剩余未出库量</th>
		  	    <th scope="col">合同备注</th>
		  	</tr>	
		  </thead>
		  <tbody>
			  	<tr v-for="htOil in htOilList" :key="htOil">
			      <td>
			  		<a class="text-primary">{{htOil.htId}}</a>
			  	  </td>	  
			  	  <td>
			  		  {{htOil.htType}}
			  	  </td>	      
			  	  <td>
			  		  {{htOil.customerName}}
			  	  </td>	 
			      <td>
			  		  {{htOil.activationDate}}
			  	  </td>	  
			      <td>
			  		  {{htOil.stopDate}}
			  	  </td>
			      <td>
			  		  {{htOil.materialName}}
			  	  </td>	
			      <td>
			  		  {{htOil.oilSpecification}}
			  	  </td>	
			      <td>
			  		  {{htOil.outputOd}}
			  	  </td>	
			      <td>
			  		  {{htOil.getOd}}
			  	  </td>	
			      <td>
			  		  {{htOil.oilPrice}}
			  	  </td>	
			      <td>
			  		  {{htOil.htNum}}
			  	  </td>	
			      <td>
			  		  {{htOil.arrangePlan}}
			  	  </td>
			      <td>
			  		  {{htOil.actualOutput}}
			  	  </td>																  
			      <td>
			  		  {{htOil.arrangeWaitDelive}}
			  	  </td>		
			      <td>
			  		  {{htOil.salesman}}
			  	  </td>																		
			      <td>
			  		  {{htOil.agreement}}
			  	  </td>																		
			      <td>
			  		  {{htOil.bondRate}}
			  	  </td>	
			      <td>
			  		  {{htOil.transportName}}
			  	  </td>	
			      <td>
			  		  {{htOil.remainPlan}}
			  	  </td>	
			      <td>
			  		  {{htOil.remainOut}}
			  	  </td>	
			      <td>
			  		  {{htOil.remarks}}
			  	  </td>																								  
			    </tr>


			<tr>
		      <td colspan="21">
		        <table class="table mb-0">
					  <thead class="table-light">
					      <tr> 
					  	  	<th>计划号/提油号</th>
					  	  	<th>合同编码</th>
					  	    <th>客商名称</th>
					  	    <th>物料名称</th>
					  	    <th>结算单价</th>
					  	    <th>合同数量</th>
					  	  	<th>计划日期</th>
					  	  	<th>计划收发数量</th>
					  	  	<th>已收发量</th>
					  		<th>剩余未收发量</th>
					  	  	<th>类型</th>
					      </tr>
					    </thead>
					  <!--stop显示列表栏-->  
					  <!--显示表格-->
					    <tbody> 
					  	  <tr>
					  		<td>
					  			<a class="text-primary" @click="updatePlanOil(planOil)">{{planOil.planId}}</a>
					  		</td>	
					  		<td>
					  		  {{planOil.htId}}
					  		</td>
					  		<td>
					  		  {{planOil.customerName}}
					  		</td>
					  		<td>
					  		  {{planOil.materialName}}
					  		</td>
					  		<td>
					  		  {{planOil.oilPrice}}
					  		</td>
					  		<td>
					  		  {{planOil.htNum}}
					  		</td>
					  		<td>
					  		  {{planOil.planDate}}
					  		</td>
					  		<td>
					  		  {{planOil.planNum}}
					  		</td>
					  		<td>
					  		  {{planOil.getNum}}
					  		</td>
					  		<td>
					  		  {{planOil.remaindeNum}}
					  		</td>
					  		<td>
					  		  {{planOil.type}}
					  		</td>
					  	</tr>
					    </tbody>
		        </table>
		      </td>
		    </tr>

		    
			<tr>
		      <td colspan="21">
		        <table class="table mb-0">
					  <tbody>
							<tr>
							  <th scope="row">收发单号</th>
							  <td>
								  <input type="text" readonly placeholder="系统自动生成" class="form-control" id="ioNo" name="ioNo" v-model="actualOil.ioNo"  aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
							  </td>
							  <th scope="row">合同编号</th>
							  <td>
								  <input type="text" readonly class="form-control" id="htId" name="htId" v-model="actualOil.htId" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
							  </td>
							</tr>
							<tr>
								<th scope="row">计划号/提油号</th>
								<td>
									<input type="text" readonly class="form-control" id="planId" name="planId" v-model="actualOil.planId" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
								</td>
								<th scope="row">收发日期</th>
								<td>
								  <input type="date" class="form-control" required id="ioDate" name="ioDate" v-model="actualOil.ioDate" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
								</td>
							</tr>
							<tr>
							  <th scope="row">收发车号</th>
							  <td>
								  <input type="text" required class="form-control" id="carNo" name="carNo" v-model="actualOil.carNo" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
							  </td>
							  <th scope="row">收发数量</th>
							  <td v-if="planOil.remaindeNum==null">
								  <input type="number"  step="0.001" :max="planOil.planNum" placeholder="保留三位小数" class="form-control" id="ioNum" name="ioNum" v-model="actualOil.ioNum" >
							  </td>							  
							  <td v-if="planOil.remaindeNum!=null">
								  <input type="number"  step="0.001" :max="planOil.remaindeNum" placeholder="保留三位小数" class="form-control" id="ioNum" name="ioNum" v-model="actualOil.ioNum"  >
							  </td>
							</tr>

							<tr class="table-secondary">
							  <th scope="row">已收发量</th>
							  <td>
								  <input type="text" readonly class="form-control" id="getNum" name="getNum" v-model="planOil.getNum" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
							  </td>
							  <th scope="row">剩余未收发量</th>
							  <td>
								  <input type="text" readonly class="form-control" id="remaindeNum" name="remaindeNum" v-model="planOil.remaindeNum" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
							  </td>
							</tr>	
							<tr class="table-secondary">
							  <th scope="row">结算单价</th>
							  <td>
								  <input type="text" readonly class="form-control" id="oilPrice" name="oilPrice" v-model="planOil.oilPrice" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
							  </td>
							  <th scope="row">总价</th>
							  <td>
								  <input type="text" readonly class="form-control" id="sum" name="sum" v-model="actualOil.sum" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
							  </td>
							</tr>					
					  </tbody>
		        </table>
		      </td>
		    </tr>
		  </tbody>
		</table>
		
		<!--stop表格-->		
		
		
		
			<form>																																										
		  <div class="form-group row">
		    <div class="col-sm-3 text-center">
		    </div>			  
		    <div class="col-sm-3 text-center">
			  <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal">确认新增</button>
		    </div>
			
		<!-- Modal -->
			<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
			  <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
				<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
			  </symbol>
			  <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
				<path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
			  </symbol>
			  <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
				<path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
			  </symbol>
			</svg>
			<div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
			  <div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
				  <div class="modal-header alert-warning">
					<svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"/></svg>
					<h5 class="modal-title" id="staticBackdropLabel">注意</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				  </div>
				  <div class="modal-body">
					新增出入库记录会生成一条账务信息，已核对数值，确认继续？
				  </div>
				  <div class="modal-footer">
					<button type="button" class="btn btn-warning col-sm-3" data-bs-dismiss="modal" @click="addActualOil()">是</button>
					<button type="button" class="btn btn-secondary col-sm-3" data-bs-dismiss="modal">否</button>
				  </div>
				</div>
			  </div>
			</div>
			<!--stop模态框 -->	

		    <div class="col-sm-3 text-center">
		      <button type="button" class="btn btn-secondary" @click="goBack()">放弃修改</button>
		    </div>
		    <div class="col-sm-3 text-center">
		    </div>			
		  </div>																																																		
			</form>
			
	</div>
	<div class="col-1"></div>
</div>
<!--Stop Card -->



</template>

<script>
	import axios from 'axios';
	import router from '../../router/index.js';
	import store from '../../store/index.js';
	import {ref,reactive} from 'vue';
				
  export default {
	  name:'ActualOilAdd',
	  setup(){
			let htOil=ref('');
			let planOil=store.state.planOil;
			let actualOil=reactive({
				planId:planOil.planId,
				htId:planOil.htId,
				customerName:'',
				customerId:planOil.customerId,
				materialName:'',
				oilPrice:'',
				htNum:'',
				planDate:'',
				planNum:'',
				getNum:'',
				remaindeNum:'',
				type:planOil.type,
				pageNum:1,
				pageSize:20,
				pages:1
			});
			// let successStatus=store.state.successStatus;
			// let failStatus=store.state.failStatus;
			let htOilList=ref('');
			axios.post('/ht/getHtOilById',planOil).then(function (response) {
				console.log(response);
				htOilList.value=response.data;
				})
				.catch(function (error) {
				console.log(error);
				});
			// store.commit('setHtOil',htOil);
//新增出入库记录
			function addActualOil(){		
				axios.post('/actual/addActualOil',actualOil).then(function (response) {
					console.log(response);
					store.commit('setActualOil',response.data.actualOil);
					store.commit('setSuccessStatus');
					router.push({ name: 'ActualOil' });
					})
					.catch(function (error) {
					store.commit('setFailStatus');
					console.log(store.state.failStatus);
					console.log(error);
					});
			}
//返回上一层
			function goBack(){
				router.push({ name: 'ActualOil'});
			}
					
		  return{
			  htOil,
			  actualOil,
			  planOil,
			  addActualOil,
			  htOilList,
			  // successStatus,
			  // failStatus,
			  goBack
		  }
	  }
  }
</script>

<style>
	
</style>

