<template>
<!--搜索框 -->  
<div class="alert alert-secondary" role="alert">
  <h4 class="alert-heading">②管理提油计划</h4>
  <hr>
  <div class="row">
		<div class="col-3 justify-content-center">
			<div class="input-group">
			  <input type="text" class="form-control" id="searchText" v-model="searchLike.searchText" @keyup.enter="search()">
			  <button type="button" class="btn btn-outline-secondary" @click="search()">{{searchLike.searchType}}</button>
			  <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
			    <span class="visually-hidden">Toggle Dropdown</span>
			  </button>
			</div>
			<div class="collapse" id="collapseExample">
			  <div class="card card-body">
					<a class="dropdown-item" @click="changeSearchType('检索客商信息')" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">检索客商信息</a>
				    <div role="separator" class="dropdown-divider"></div>
				    <a class="dropdown-item" @click="changeSearchType('检索合同编码')" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">检索合同编码</a>
			  </div>
			</div>
		</div>	  
	  
	  
		<div class="col">
			<!-- 分页-->
			<div class="hstack gap-2 justify-content-center">
			    <div class="">
					<nav aria-label="Page navigation example">
					  <ul class="pagination ">
						<li class="page-item"><a class="page-link">每页显示：</a></li>
					    <li class="page-item">
							<select class="form-control" id="exampleFormControlSelect1" v-model="pageInfo.pageSize" @click="getPlanOilPage()">
							  <option>3</option>
							  <option>5</option>
							  <option>10</option>
							  <option>50</option>
							  <option>1000</option>
							</select>
						</li>
					  </ul>	  
					</nav>
				</div>
			    <div class="">
					<nav aria-label="Page navigation example">
					  <ul class="pagination justify-content-center">
						<li class="page-item"><a class="page-link" href="#skip" @click="showFirstPage()">1</a></li>
						<li class="page-item">
						  <a class="page-link" href="#skip" aria-label="Previous" @click="showLastPage()">
							<span aria-hidden="true">&laquo;</span>
						  </a>
						</li>
						<li class="page-item active"><a class="page-link" >{{planOilListPage.pageNum}}</a></li>
						<li class="page-item">
						  <a class="page-link" href="#skip" aria-label="Next" @click="showNextPage()">
							<span aria-hidden="true">&raquo;</span>
						  </a>
						</li>
						<li class="page-item"><a class="page-link" href="#skip" @click="showEndPage()">{{planOilListPage.pages}}</a></li>
					  </ul>
					</nav>
				</div>
				<div class="">
					<nav aria-label="Page navigation example">
					  <ul class="pagination ">
					    <li class="page-item"><input type="number" class="form-control " v-model="pageInfo.pageNum" @keyup.enter="getPlanOilPage()"></li>
					    <li class="page-item"><a class="page-link" href="#skip" @click="getPlanOilPage()">Go!</a></li>
					  </ul>
					</nav>
				</div>
			</div>
			
			<!-- stop分页-->		
		</div>
		<div class="col-2">
			<button class="btn btn-success m-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample12" aria-expanded="false" aria-controls="collapseExample12">
				Excel处理
			</button>
			<div class="collapse" id="collapseExample12">
			  <div class="card card-body">
				  <div class="mb-3">
				    <input class="form-control form-control-sm" id="formFileSm" type="file">
					<div class="">
						<label class="form-label">Excle导入</label>
						<button class="btn btn-warning m-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample12" aria-expanded="false" aria-controls="collapseExample12">
							导入
						</button>						
					</div>
				  </div>
				  <div role="separator" class="dropdown-divider"></div>
				  <div class="mb-3">
				    <label class="form-label">Excle导出</label>
					<a  class="btn btn-success m-1" href="http://localhost:8080/plan/downloadFile?fileName=PlanOilList.xlsx">导出</a>
				  </div>
			  </div>
			</div>
		</div>
  </div>

</div>
<!--stop搜索框 -->    


<!--表格 -->	
<table class="table table-hover">
  <thead class="sticky-top table-light">
    <tr> 
		<th>序号</th>
	  	<th>计划号/提油号</th>
	  	<th>合同编码</th>
	    <th>客商名称</th>
	    <th>物料名称</th>
	    <th>结算单价</th>
	    <th>合同数量</th>
	  	<th>计划日期</th>
	  	<th>计划收发数量</th>
	  	<th>已收发量</th>
		<th>剩余未收发量</th>
	  	<th>类型</th>
		<th>操作日志</th>
    </tr>
  </thead>
<!--stop显示列表栏-->  
<!--显示表格-->
  <tbody> 
	  <tr v-for="(planOil,index) in planOilList" :key="planOil" >
	    <th scope="row">{{index+1}}</th>
		<td>
			<a class="text-primary" @click="updatePlanOil(planOil)">{{planOil.planId}}</a>
		</td>	
		<td>
		  {{planOil.htId}}
		</td>
		<td>
		  {{planOil.customerName}}
		</td>
		<td>
		  {{planOil.materialName}}
		</td>
		<td>
		  {{planOil.oilPrice}}
		</td>
		<td>
		  {{planOil.htNum}}
		</td>
		<td>
		  {{planOil.planDate}}
		</td>
		<td>
		  {{planOil.planNum}}
		</td>
		<td>
		  {{planOil.getNum}}
		</td>
		<td>
		  {{planOil.remaindeNum}}
		</td>
		<td>
		  {{planOil.type}}
		</td>
		<td>
		  {{planOil.userName}}
		</td>		
	</tr>
  </tbody>
</table>
<!--stop表格 -->	

<!--分页 -->
<div class="hstack gap-2 justify-content-center">
	  <nav aria-label="Page navigation example">
	  		<ul class="pagination justify-content-center">
	  		<li class="page-item"><a class="page-link" href="#skip" @click="showFirstPage()">1</a></li>
	  		<li class="page-item">
	  		  <a class="page-link" href="#skip" aria-label="Previous" @click="showLastPage()">
	  			<span aria-hidden="true">&laquo;</span>
	  		  </a>
	  		</li>
	  		<li class="page-item active"><a class="page-link" >{{planOilListPage.pageNum}}</a></li>
	  		<li class="page-item">
	  		  <a class="page-link" href="#skip" aria-label="Next" @click="showNextPage()">
	  			<span aria-hidden="true">&raquo;</span>
	  		  </a>
	  		</li>
	  		<li class="page-item"><a class="page-link" href="#skip" @click="showEndPage()">{{planOilListPage.pages}}</a></li>
	  		</ul>
	  </nav>
	  <nav aria-label="Page navigation example">
	  		<ul class="pagination">
	  		  <li class="page-item"><input type="number" class="form-control" v-model="pageInfo.pageNum"></li>
	  		  <li class="page-item"><a class="page-link" href="#skip" @click="getPlanOilPage()">Go!</a></li>
	  		</ul>
	  </nav>
</div>



<!--stop分页 -->
<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>

</template>

<script>
	import axios from 'axios';
	import router from '../../router/index.js'
	import {ref,reactive} from 'vue';
	import store from '../../store/index.js';


	export default{
		name:'PlanOil',
		components:{
		},
		setup(){  
			let planOilList=ref('');
			let planOil=ref('');
			let searchLike=reactive({
				searchText:'',
				searchType:'检索客商信息'
			})
			//分页
			let pageInfo=reactive({
				pageNum:1,
				pageSize:5,
				total:0,
				pages:0
			});		
			let planOilListPage=ref('');
					
			axios.post('/plan/getPlanOilList',planOil)
			  .then(function (response) {
				console.log(response);
				planOilListPage.value=response.data;
				planOilList.value=planOilListPage.value.planOilList;
			  })
			  .catch(function (error) {
				console.log(error);
			  });	

			planOil=store.state.planOil;

//搜索计划			
			function search(){
				axios.post('/plan/getPlanOilListLike',searchLike).then(function (response) {
						console.log(response);
						planOilList.value=response.data;
						})
						.catch(function (error) {
						console.log(error);
						});	
			}
//新增计划
			function addPlanOil(){
				axios.post('/plan/addPlanOil',planOil).then(function (response) {
						console.log(response);
						alert("添加成功");
						router.push('/backHelp');
						})
						.catch(function (error) {
						alert("添加失败，连接错误。");	
						console.log(error);
						});	
			}
//修改提油计划			
			const updatePlanOil= (planOil) =>{
				store.commit('setPlanOil',planOil);
				router.push({ name: 'PlanOilDetail' });
			};	
//.....分页...........			
		const showFirstPage= () =>{
			pageInfo.pageNum=1;
			getPlanOilPage();		  
		};	  
		const showLastPage= () =>{
			pageInfo.pageNum=planOilListPage.value.pageNum-1;
			getPlanOilPage();
		};
		const showEndPage= () =>{
			pageInfo.pageNum=planOilListPage.value.pages;
			getPlanOilPage();
		};		  
		const showNextPage= () =>{
			pageInfo.pageNum=planOilListPage.value.pageNum+1;
			getPlanOilPage();
		};
		function getPlanOilPage(){
			axios.post('/plan/getPlanOilList',pageInfo)
			  .then(function (response) {
				console.log(response);
				planOilListPage.value=response.data;
				planOilList.value=planOilListPage.value.planOilList;
			  })
			  .catch(function (error) {
				console.log(error);
			  });	
		}
		const changeSearchType= (st) =>{
			  searchLike.searchType=st;
		  };
//.....stop分页..................	
		
			return{
				planOilList,
				planOil,
				search,
				addPlanOil,
				updatePlanOil,
				changeSearchType,
//分页			
				pageInfo,
				getPlanOilPage,
				showNextPage,
				showEndPage,
				showLastPage,
				showFirstPage,
				planOilListPage,
				searchLike
			}
		}
	}
</script>

<style>
</style>
